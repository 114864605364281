import React, { useState, useEffect } from 'react'
import { Box, TableCell, IconButton, ButtonBase } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { PlaylistAdd } from '@material-ui/icons'
import { Field } from 'formik'
import { MaterialAvailabilityKey } from '@src/types/graphql-types'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import HandleMarkup from '@src/components/HandleMarkup'
import SDSLink from '@src/components/SDSLink'
import AddToListButton from '../../AddToListButton'
import LiquidEmeraldQuantityAdapter from '../../LiquidEmeraldQuantityAdapter'
import LiquidQuantityInputAdapter from '../../LiquidQuantityInputAdapter'
import RestrictedItemModal from '@src/components/RestrictedItemModal'
import LockIcon from '@src/icons/LockIcon'
import MaterialPricingDisplay from '../../MaterialPricingDisplay'
import MaterialAvailabilityDisplay from '../../MaterialAvailabilityDisplay'
import AdditionalInfoIcon from '@src/components/AdditionalInfoIcon'
import AdditionalInfoDialog from '@src/components/AdditionalInfoDialog'
import clsx from 'clsx'
import { useStyles as availabilityStyles } from '../styles'
import { sendProductInfoInteractionEvent } from '@src/utils/analytics'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import {
  ProductDetailsEventCategory as eventCategory,
  ProductDetailsEventAction as eventAction,
} from '@utils/analytics/enums'
import dynamic from 'next/dynamic'
import messages from '@utils/messages'
import { useIntl } from 'react-intl'
import BuyNowButton from '@src/components/BuyNowButton'
import MaterialTextWithCopy from '@src/components/MaterialTextWithCopy/MaterialTextWithCopy'
import PromoBundleButton from '@src/components/PromoBundleButton/PromoBundleButton'
import { useRouter } from '@src/routes'
import { useIndonesiaUser } from '@utils/useChinaUser'
import ExpiryDate from '@src/components/ExpiryDate'

const Tooltip = dynamic(() => import('@material-ui/core/Tooltip'), {
  ssr: false,
})

const useStyles = makeStyles((theme: Theme) => ({
  qtyInput: {
    height: 32,
    maxWidth: theme.typography.pxToRem(102.5),
    minWidth: theme.typography.pxToRem(102.5),
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  tCell: {
    verticalAlign: 'middle',
  },
  materialNumber: {
    width: '15%',
    '&$hidePriceMessageKey': {
      width: '5%',
    },
  },
  packSize: {
    width: '10%',
    '&$hidePriceMessageKey': {
      width: '5%',
    },
  },
  availability: {
    width: '45%',
    '&$hidePriceMessageKey': {
      width: '40%',
    },
  },
  price: {
    width: '10%',
  },
  quantity: {
    width: '15%',
    '&$hidePriceMessageKey': {
      width: '10%',
    },
  },
  // conditional column changes
  vendorSKU: {
    width: '10%',
  },
  materialNumberWithSku: {
    width: '10%',
  },
  packSizeWithSku: {
    width: '5%',
  },
  sds: {
    width: '10%',
  },
  availabilityWithSds: {
    width: '40%',
  },
  hidePriceMessageKey: {
    width: '20%',
  },
  addToListButton: {
    padding: 0,
    fontSize: theme.typography.pxToRem(24),
  },
  addToListIcon: {
    fill: theme.palette.primary.main,
  },
  lockIcon: {
    flex: 1,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  buyNowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: 100,
  },
  labelFontSize: {
    fontSize: theme.typography.pxToRem(12),
  },
  minimumQuantityError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
}))

interface ProductMaterialRowProps {
  productName?: string
  productDescription?: string
  productId?: string
  material: ValidMaterialPricingDetailFragment
  initialShipEstimate: React.ReactFragment
  canAddToCart: boolean
  hideAddToCartForPrepackItems: boolean
  index: number
  displayPromotionalBundlesAnchor?: boolean
  displaySDS?: boolean
  countryCode?: string
  isMarketplace?: boolean
  renderBuyNow?: boolean
  rowValues?: {
    materialNumber: string
    quantity: number | string
    dealerId: string | null
  }
  setMultipleMinimumQuantityError?: (name: string, hasError: boolean) => any
  erpType?: string[]
}

const ProductMaterialRow: React.FC<ProductMaterialRowProps> = ({
  productName,
  productDescription,
  productId,
  material,
  canAddToCart,
  hideAddToCartForPrepackItems,
  initialShipEstimate,
  displayPromotionalBundlesAnchor,
  index,
  displaySDS,
  countryCode,
  isMarketplace = false,
  renderBuyNow = false,
  rowValues,
  setMultipleMinimumQuantityError,
  erpType,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const router = useRouter()
  const isIndonesiaUser = useIndonesiaUser()
  // Import from parent styles.ts to keep listPrice consistent
  const availabilityClasses = availabilityStyles()
  const availability = material.availabilities?.[0]
  const displayPromoBundleLink =
    displayPromotionalBundlesAnchor && material.isPBAvailable && router.query

  const inquireForPAndA =
    availability &&
    availability?.key === MaterialAvailabilityKey.InquireForPriceAndAvailability

  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false)
  const [restrictedItemModalOpen, setRestrictedItemModalOpen] = useState(false)

  const [
    showMultipleMinimumQuantityError,
    setShowMultipleMinimumQuantityError,
  ] = useState<boolean>(false)

  const toggleRestrictedItemModalOpen = () => {
    setRestrictedItemModalOpen((open) => !open)
  }

  useEffect(() => {
    if (additionalInfoOpen) {
      sendProductInfoInteractionEvent(
        {
          action: 'view additional information',
          section: 'pricing and availability',
          component: 'list',
          elementType: 'icon',
          elementText: 'view additional information',
          productId: material.product,
          productBrand: material.brand,
          productVariant: material.materialNumber,
        },
        {
          eventCategory: eventCategory.PricingAndAvailability,
          eventAction: eventAction.AdditionalInfo,
          eventLabel: material.materialNumber,
          eventInteractionType: 0,
        }
      )
    }
  }, [additionalInfoOpen])

  return (
    <>
      <TableCell
        className={clsx(
          classes.tCell,
          material.type === 'ThirdPartyProvider'
            ? classes.materialNumberWithSku
            : classes.materialNumber,
          material.hidePriceMessageKey && classes.hidePriceMessageKey
        )}
      >
        <MaterialTextWithCopy materialNumber={material.materialNumber} />
        {displayPromoBundleLink && (
          <Box pt={2}>
            <PromoBundleButton
              materialNumber={material.materialNumber}
              brand={material.brand}
              productName={productName ?? ''}
              erp_type={erpType}
            />
          </Box>
        )}
      </TableCell>
      {material.type === 'ThirdPartyProvider' ? (
        <TableCell className={clsx(classes.tCell, classes.vendorSKU)}>
          {material.vendorSKU}
        </TableCell>
      ) : null}
      <TableCell
        className={clsx(
          classes.tCell,
          material.type === 'ThirdPartyProvider'
            ? classes.packSizeWithSku
            : classes.packSize,
          material.hidePriceMessageKey && classes.hidePriceMessageKey
        )}
      >
        <HandleMarkup value={material.packageSize} />
      </TableCell>
      <TableCell
        className={clsx(
          classes.tCell,
          displaySDS ? classes.availabilityWithSds : classes.availability,
          material.hidePriceMessageKey && classes.hidePriceMessageKey
        )}
      >
        <MaterialAvailabilityDisplay
          canAddToCart={canAddToCart}
          material={material}
          initialShipEstimate={initialShipEstimate}
          displayPromotionalBundlesAnchor={displayPromotionalBundlesAnchor}
          countryCode={countryCode}
          gaDetailsClick={() =>
            sendPricingAvailabilityInteractionEvent({
              action: 'check availability details',
              section: 'related products',
              component: 'list',
              elementType: 'link',
              elementText: 'details',
              material,
            })
          }
          source={'related products'}
          gaSection={router.asPath.includes('/search') ? undefined : 'products'}
          gaComponent={'list'}
        />
        {/* ExpiryDate */}
        <ExpiryDate
          expirationDate={material.expirationDate}
          displayColon
          displayInline
          outerClassName={classes.labelFontSize}
        />
        {/* minimum Order Quantity */}
        {material.minOrderQuantity && material.minOrderQuantity > 1 ? (
          <div
            className={clsx(
              classes.labelFontSize,
              showMultipleMinimumQuantityError && classes.minimumQuantityError
            )}
          >
            {intl.formatMessage(messages.MINIMUM_ORDER_QUANTITY)}
            {material.minOrderQuantity}
          </div>
        ) : null}
      </TableCell>
      {displaySDS && (
        <TableCell className={clsx(classes.tCell, classes.sds)}>
          <SDSLink
            sdsLanguages={material.sdsLanguages}
            productNumber={material.materialNumber}
            brandKey={material.brand}
          />
        </TableCell>
      )}
      <TableCell
        className={clsx(
          classes.tCell,
          classes.price,
          material.hidePriceMessageKey && classes.hidePriceMessageKey
        )}
      >
        {!inquireForPAndA ? (
          <Box
            data-testid={`P&A-row-price-${material.brand}-${material.materialNumber}`}
            id={`P&A-row-price-${material.brand}-${material.materialNumber}`}
          >
            <MaterialPricingDisplay
              material={material}
              listPriceClass={availabilityClasses.listPrice}
            />
          </Box>
        ) : null}
      </TableCell>
      <TableCell className={clsx(classes.tCell, classes.quantity)}>
        <Box display="flex" alignItems="center">
          {canAddToCart && !hideAddToCartForPrepackItems && !inquireForPAndA ? (
            <>
              {material?.isBlockedProduct ? (
                <ButtonBase
                  className={classes.lockIcon}
                  onClick={() => setRestrictedItemModalOpen(true)}
                >
                  <LockIcon fontSize="inherit" />
                </ButtonBase>
              ) : material.minOrderQuantity && material.minOrderQuantity > 1 ? (
                <Field
                  name={`${index}.quantity`}
                  component={LiquidEmeraldQuantityAdapter}
                  className={classes.qtyInput}
                  data-testid={`P&A-row-quantity-${material.brand}-${material.materialNumber}`}
                  id={`P&A-row-quantity-${material.brand}-${material.materialNumber}`}
                  associatedComponent={`P&A-row-quantity-${material.brand}-${material.materialNumber}`}
                  materialNumber={material.materialNumber}
                  minOrderQuantity={material.minOrderQuantity}
                  setShowMultipleMinimumQuantityError={
                    setShowMultipleMinimumQuantityError
                  }
                  setMultipleMinimumQuantityError={
                    setMultipleMinimumQuantityError
                  }
                  isIndonesiaUser={isIndonesiaUser}
                />
              ) : (
                <Field
                  name={`${index}.quantity`}
                  component={LiquidQuantityInputAdapter}
                  className={classes.qtyInput}
                  data-testid={`P&A-row-quantity-${material.brand}-${material.materialNumber}`}
                  id={`P&A-row-quantity-${material.brand}-${material.materialNumber}`}
                  associatedComponent={`P&A-row-quantity-${material.brand}-${material.materialNumber}`}
                  max={material?.availableQtyInStock ?? 9999}
                />
              )}
            </>
          ) : null}
          {restrictedItemModalOpen && (
            <RestrictedItemModal
              open={restrictedItemModalOpen}
              onCancel={toggleRestrictedItemModalOpen}
            />
          )}
          {!inquireForPAndA ? (
            <Box display="flex" alignItems="center" ml={6.5}>
              <AdditionalInfoIcon setDialogOpen={setAdditionalInfoOpen} />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" ml={31.5}>
              <AdditionalInfoIcon setDialogOpen={setAdditionalInfoOpen} />
            </Box>
          )}
          {(isMarketplace || canAddToCart) && !hideAddToCartForPrepackItems ? (
            <>
              <Box ml={2} data-testid="lists_add-to-list-paa-icon">
                <AddToListButton
                  materialNumber={material.materialNumber}
                  materialId={material.materialId}
                  quantity={Number(rowValues?.quantity) || 1}
                  productName={productName || ''}
                  productId={productId || ''}
                  brand={material.brand}
                  type={material.type}
                  trigger={(onTriggerClick) => (
                    <Tooltip
                      title={intl.formatMessage(messages.ADD_TO_LIST)}
                      placement="top"
                      enterDelay={1000}
                    >
                      <IconButton
                        className={clsx(classes.addToListButton)}
                        onClick={onTriggerClick}
                      >
                        <PlaylistAdd
                          className={classes.addToListIcon}
                          fontSize="inherit"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                />
              </Box>
              {renderBuyNow ? (
                <Box mr={2} className={classes.buyNowContainer}>
                  {material?.isBuyNow ? (
                    <BuyNowButton
                      materialNumber={rowValues?.materialNumber || ''}
                      quantity={Number(rowValues?.quantity) || 1}
                      size="small"
                      marketplace={isMarketplace}
                      gaType="search result page"
                    />
                  ) : null}
                </Box>
              ) : null}
            </>
          ) : null}
        </Box>
      </TableCell>
      {/* TODO Move AdditionalInfoDialog to parent component to decrease unnecessary repetition. */}
      <AdditionalInfoDialog
        open={additionalInfoOpen}
        additionalInfo={material.additionalInfo || {}}
        productNumber={material.materialNumber}
        productName={productName}
        packageType={material.packageType}
        packageSize={material.packageSize}
        description={productDescription || material.materialDescription}
        handleClose={() => setAdditionalInfoOpen(false)}
      />
    </>
  )
}

export default ProductMaterialRow
