import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useApolloClient } from '@apollo/react-hooks'
import { ApolloQueryResult } from 'apollo-client'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Field, Form, Formik, FormikProps } from 'formik'
import {
  DiscontinuedMaterialPricingDetailFragment,
  ValidMaterialPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import { PRICING_FOR_PRODUCT_CUSTOMER_SPECIFIC } from '@src/queries/PricingAndAvailabilityForCustomerQuery'
import messages from '@utils/messages'
import SpacingBox from '@src/components/SpacingBox'
import { Button } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import {
  ProductListPagesEnum,
  setStoreAddToCartDataForAnalytics,
} from '@utils/tealiumAnalytics'
import { useAddToCartWithGlobalSnackbar } from '@src/utils/useCart'
import AddToListButton from '@src/components/AddToListButton'
import LiquidSelectAdapter from '@src/components/LiquidSelectAdapter/LiquidSelectAdapter'
import { SitePreference, useCurrentUser } from '@src/utils/useCurrentUser'
import MaterialAvailabilityPreview from '@src/components/MaterialAvailabilityPreview/MaterialAvailabilityPreview'
import vrStyles from '@src/styles/utils/vrStyles'
import LiquidQuantityInput from '@src/components/LiquidQuantityInput'
import HandleMarkup from '@src/components/HandleMarkup'
import ShippingMessages from './ShippingMessages'
import InquireMessages from './InquireMessages'
import DisplayPrice from './DisplayPrice'
import PreferredDealerSection from '../PreferredDealerSection'
import BuyNowButton from '@src/components/BuyNowButton'
import PromoBundleButton from '@src/components/PromoBundleButton/PromoBundleButton'
import ReplacementProductsCarousel from '@src/components/ReplacementProductsCarousel'
import { ProductCardType } from '@src/utils/searchUtils'
import { CatalogImage, CatalogType } from '@src/types/graphql-types'
import useResponsiveSizes from '@src/utils/useResponsiveSizes'
import DiscontinuedProductAlert from '@src/components/DiscontinuedProductAlert'
import { MultiCountryPricingAndAvailabilityQuery } from '@src/queries/MultiCountryPricingAndAvailabilityQuery.generated'
import ExpiryDate from '@src/components/ExpiryDate'
import { hasMultipleMiniMumOrderQuantityError } from '@utils/cartUtils'
import { useIndonesiaUser } from '@utils/useChinaUser'
import {
  TrackedAvailabilityMessageContext,
  TrackedAvailabilityMessageProvider,
} from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import { setAddToCartData } from '@utils/analytics/cartAndCheckoutEvents'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { useMultiCountryPricingTabs } from '@src/components/ProductPriceAvailability/useMultiCountryPricingTabs'

const { vr2, vr6 } = vrStyles
const useStyles = makeStyles((theme: Theme) => ({
  body: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
  },
  materialInfo: {
    ...vr2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  availability: {
    ...vr6,
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(16),
  },
  tabs: {
    color: theme.palette.primary.main,
    '& .Mui-selected': {
      color: theme.palette.common.black,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.black,
      height: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(5),
      '& .MuiTabs-indicator': {
        height: theme.spacing(0.5),
      },
    },
  },
  tab: {
    fontSize: theme.typography.pxToRem(18),
    paddingBottom: 0,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(6),
    textTransform: 'capitalize',
    minWidth: 'auto',
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: 0,
    },
  },
  expiryDate: {
    fontSize: theme.typography.pxToRem(12),
  },
  minimumQuantityError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  minimumQuantity: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  materialPricing: ValidMaterialPricingDetailFragment[]
  canAddToCart: boolean
  renderSecondaryAction?(): ReactNode
  marketplaceOfferId?: string
  productName?: string
  erpType?: string[] | null
  productKey?: string
  brandKey?: string
  images?: CatalogImage[] | undefined
  discontinuedPricingInfo?: DiscontinuedMaterialPricingDetailFragment | null
  multiCountryData?: MultiCountryPricingAndAvailabilityQuery
}

type MobilePAFormValues = {
  materialNumber: string
  quantity: number
  marketplaceOfferId?: string
}

const MobileProductPriceAvailability: FC<Props> = ({
  materialPricing,
  canAddToCart,
  renderSecondaryAction = null,
  marketplaceOfferId,
  productName,
  erpType,
  productKey,
  brandKey,
  images,
  discontinuedPricingInfo,
  multiCountryData,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const client = useApolloClient()
  const { formatMessage } = useIntl()
  const getSize = useResponsiveSizes()
  const materialNumber = materialPricing?.length
    ? materialPricing[0]?.materialNumber
    : ''
  const initialFormValues = {
    materialNumber: materialNumber,
    quantity: 1,
    marketplaceOfferId,
  }
  const [formikInitialValues, setFormikInitialValues] =
    useState(initialFormValues)
  const { getSitePreference, isDTAQZuCustomer, isMultiCountryUser } =
    useCurrentUser()
  const isIndonesiaUser = useIndonesiaUser()
  const {
    publicRuntimeConfig: { featureFlags },
  } = getConfig()
  const hideAddToCartForPrepackItems = getSitePreference(
    SitePreference.HideAddToCartForPrepackItems
  )
  const [selectedMaterial, setSelectedMaterial] = useState(materialPricing[0])
  const [hasMoqError, setHasMoqError] = useState(() =>
    hasMultipleMiniMumOrderQuantityError(
      initialFormValues.quantity,
      selectedMaterial?.minOrderQuantity,
      isIndonesiaUser
    )
  )
  const [tabValue, setTabValue] = useState('')
  const [isInquire, setIsInquire] = useState(false)
  const [contactInfo, setContactInfo] = useState('')
  const [customerPricingResponse, setCustomerPricingResponse] =
    useState<ApolloQueryResult<any>>()
  const preferredDealerId = getSitePreference(SitePreference.PreferredDealerId)
  const isMissingDealer = Boolean(!preferredDealerId)
  const isBuildingBlocks =
    router.query?.focus === 'buildingblocks' || router.query?.context === 'bbe'
  const showPreferredDealerSection =
    featureFlags.dealerCartEnabled &&
    isDTAQZuCustomer &&
    !isBuildingBlocks &&
    !isMissingDealer

  const comparePage = router.asPath.includes('/compare')
  const addToCart = useAddToCartWithGlobalSnackbar()
  const ctx = useContext(TrackedAvailabilityMessageContext)

  const handleAddMaterialToCart = async (
    values: MobilePAFormValues,
    { setSubmitting, resetForm }
  ) => {
    setStoreAddToCartDataForAnalytics({
      items: [selectedMaterial],
      productListPageName: router.asPath.includes('/search')
        ? ProductListPagesEnum.SearchResultPage
        : ProductListPagesEnum.ProductDetailPage,
    })
    setAddToCartData(
      [values],
      [selectedMaterial],
      AddToCartPagesEnum.SearchResultPage
    )

    await addToCart(
      [
        {
          materialNumber: values.materialNumber,
          quantity: values.quantity,
          marketplaceOfferId: marketplaceOfferId,
        },
      ],
      setSubmitting,
      resetForm,
      selectedMaterial.catalogType === 'marketplace'
    )
    const hasMoqError = hasMultipleMiniMumOrderQuantityError(
      initialFormValues.quantity,
      selectedMaterial?.minOrderQuantity,
      isIndonesiaUser
    )
    setHasMoqError(hasMoqError)
  }

  const handleSkuChange = (value) => {
    const [selectedMaterial]: ValidMaterialPricingDetailFragment[] =
      materialPricing.filter((material) => material?.materialNumber === value)
    setSelectedMaterial(selectedMaterial)
  }

  const handleTabChange = (_, value) => {
    setTabValue(value)

    const selectedItem =
      multiCountryData?.getPricingForMultiCountryProducts?.filter((item) => {
        return item?.country === value
      })[0]
    const [material] = selectedItem?.materialPricing ?? []
    setSelectedMaterial(material)
  }

  const multiCountryPricingTabs = useMultiCountryPricingTabs(
    multiCountryData,
    handleTabChange
  )

  const handlePriceUpdateForDealer = async (
    dealerId: string | null,
    _values: any,
    materialPricing: ValidMaterialPricingDetailFragment[]
  ) => {
    const items = materialPricing.map((item) => ({
      sku: item.materialNumber,
      brand: item.brand,
      listPrice: item.listPrice,
      product: `SBU${item.productGroupSBU}`,
      qty: 1,
    }))
    const cspVariables = {
      products: [
        {
          dealerId,
          items,
        },
      ],
    }
    const result = await client.query({
      query: PRICING_FOR_PRODUCT_CUSTOMER_SPECIFIC,
      variables: {
        input: cspVariables,
      },
    })
    setCustomerPricingResponse(result)
  }

  const handleQuantityChange = (event, form, field) => {
    form.setFieldValue(field.name, event)
    const hasMoqError = hasMultipleMiniMumOrderQuantityError(
      event,
      selectedMaterial?.minOrderQuantity,
      isIndonesiaUser
    )
    setHasMoqError(hasMoqError)
  }

  const handleGAdetailsClick = () => {
    sendPricingAvailabilityInteractionEvent({
      action: 'check availability details',
      section: 'modal p&a',
      component: 'modal',
      elementType: 'link',
      elementText: 'details',
      material: selectedMaterial,
    })
  }

  /**
   * have to useMemo here bc we need this hook to run before render
   * useEffect works in the UI but causes matrial ui warning as result of formik initial props
   * not having the correct data until second render
   * Ex: Material-UI: you have provided an out-of-range value `G8898-500G` for the select (name="materialNumber") component.
   * Consider providing a value that matches one of the available options or ''.
   * The available values are `T1503-25G`, `T1503-100G`.
   */
  useMemo(() => {
    return setFormikInitialValues(initialFormValues)
    // NOTE: the dependency array requires productNumber, not initialFormValues to operate properly.
    // Also, see the above comment about using useMemo vs. useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialNumber])

  useEffect(() => {
    const hasMoqError = hasMultipleMiniMumOrderQuantityError(
      initialFormValues.quantity,
      selectedMaterial?.minOrderQuantity,
      isIndonesiaUser
    )
    setHasMoqError(hasMoqError)
  }, [selectedMaterial])

  useEffect(() => {
    const isInquire =
      !!selectedMaterial?.availabilities?.[0]?.displayInquireLink
    setIsInquire(isInquire)

    const contactInfo = selectedMaterial?.availabilities?.[0]?.contactInfo

    const inquirePriceContactInfo = [
      contactInfo?.contactPhone,
      contactInfo?.contactEmail,
    ]
      .filter((contactValue) => !!contactValue)
      .join(` ${formatMessage(messages.OR)} `)

    setContactInfo(inquirePriceContactInfo)
  }, [selectedMaterial, formatMessage])

  useEffect(() => {
    if (customerPricingResponse?.data) {
      const cspProducts =
        customerPricingResponse?.data?.getCustomerPricingForProduct?.products
      const updatedItems = cspProducts?.[0]?.items
      materialPricing.forEach((material) => {
        const updatedPrice = updatedItems?.find(
          (item) => item?.sku === material.materialNumber
        )?.price

        material.price = updatedPrice
      })
      const updatedSelectedMaterial = materialPricing.find(
        (material) =>
          material.materialNumber === selectedMaterial.materialNumber
      )
      if (updatedSelectedMaterial) {
        setSelectedMaterial(updatedSelectedMaterial)
      }
    }
  }, [customerPricingResponse?.data])

  // sets the first country as the default tab
  useEffect(() => {
    if (multiCountryData?.getPricingForMultiCountryProducts?.length) {
      const [firstCountry] = multiCountryData?.getPricingForMultiCountryProducts
      setTabValue(firstCountry?.country ?? '')
    }
  }, [])

  // Discontinued products will not have materialPricing
  if (
    (!Array.isArray(materialPricing) || !materialPricing.length) &&
    discontinuedPricingInfo
  ) {
    // the main product that is being compared
    const mainProduct = {
      productKey,
      brandKey,
      images,
    }

    return (
      <>
        <DiscontinuedProductAlert
          discontinuedPricingInfo={discontinuedPricingInfo}
        />
        {!comparePage && discontinuedPricingInfo?.replacementProducts ? (
          <ReplacementProductsCarousel
            type={ProductCardType.Recommended}
            preloadCardImages
            mainProduct={mainProduct}
            products={discontinuedPricingInfo?.replacementProducts}
            slidesToShow={getSize({ xs: 1, sm: 2, lg: 3 })}
            withoutControls={
              discontinuedPricingInfo?.replacementProducts?.length === 1 ||
              discontinuedPricingInfo?.replacementProducts?.length <= 4
            }
          />
        ) : null}
      </>
    )
  }

  // When navigating to a discontinued product and then back, selectedMaterial could be null
  if (!selectedMaterial) {
    setSelectedMaterial(materialPricing[0])
  }

  return (
    <div className={classes.body}>
      <Formik
        initialValues={formikInitialValues}
        onSubmit={handleAddMaterialToCart}
        enableReinitialize
      >
        {({
          isSubmitting,
          initialValues,
          values,
          setFieldValue,
        }: FormikProps<MobilePAFormValues>) => {
          const propsNotReinitialzied =
            formikInitialValues.materialNumber !== initialValues.materialNumber

          return propsNotReinitialzied ? null : (
            <>
              <Form>
                {isMultiCountryUser && multiCountryData
                  ? multiCountryPricingTabs
                  : null}

                {isMultiCountryUser ? (
                  multiCountryData?.getPricingForMultiCountryProducts?.map(
                    (countryData, index) => {
                      if (tabValue === countryData?.country) {
                        return (
                          <div key={index}>
                            {/* Product SKU Selecter */}
                            <SpacingBox mb={1}>
                              <label htmlFor="TEMP">
                                <FormattedMessage {...messages.SKU} />
                              </label>
                            </SpacingBox>
                            <SpacingBox mb={4}>
                              <Field name="materialNumber">
                                {({ form, field, meta }) => (
                                  <LiquidSelectAdapter
                                    field={field}
                                    meta={meta}
                                    form={form}
                                    size="large"
                                    onChange={(event) => {
                                      field.onChange(event)
                                      form.setFieldValue('quantity', 1)
                                      handleSkuChange(event.target.value)
                                    }}
                                    disabled={
                                      isSubmitting ||
                                      countryData?.materialPricing.length < 2
                                    }
                                    options={countryData?.materialPricing.map(
                                      (material) => ({
                                        label: material.materialNumber,
                                        value: material.materialNumber,
                                      })
                                    )}
                                  />
                                )}
                              </Field>
                            </SpacingBox>

                            <div className={classes.materialInfo}>
                              <HandleMarkup
                                value={selectedMaterial.packageSize}
                              />
                            </div>

                            {!isInquire && (
                              <DisplayPrice material={selectedMaterial} />
                            )}

                            {/* promo bundle button */}
                            <SpacingBox mt={2}>
                              {selectedMaterial.isPBAvailable ? (
                                <PromoBundleButton
                                  materialNumber={
                                    selectedMaterial.materialNumber
                                  }
                                  brand={selectedMaterial.brand}
                                  productName={productName ?? ''}
                                  erp_type={erpType}
                                />
                              ) : null}
                            </SpacingBox>

                            {/* availability */}
                            <SpacingBox mb={6} mt={4}>
                              <MaterialAvailabilityPreview
                                material={selectedMaterial}
                                canAddToCart={canAddToCart}
                                gaDetailsClick={handleGAdetailsClick}
                              />
                            </SpacingBox>

                            {/* shipping messages */}
                            <ShippingMessages material={selectedMaterial} />

                            {/* ExpiryDate */}
                            <ExpiryDate
                              expirationDate={selectedMaterial?.expirationDate}
                              displayColon
                              displayInline
                              outerClassName={classes.expiryDate}
                            />
                            {/* minimum Order Quantity */}
                            {selectedMaterial?.minOrderQuantity &&
                            selectedMaterial?.minOrderQuantity > 1 ? (
                              <div
                                className={clsx(classes.minimumQuantity, {
                                  hasMoqError: classes.minimumQuantityError,
                                })}
                              >
                                <FormattedMessage
                                  {...messages.MINIMUM_ORDER_QUANTITY}
                                />
                                {selectedMaterial?.minOrderQuantity}
                              </div>
                            ) : null}

                            {!!isInquire ? (
                              // Inquire messages
                              <InquireMessages contactInfo={contactInfo} />
                            ) : (
                              <>
                                {canAddToCart &&
                                !hideAddToCartForPrepackItems ? (
                                  <>
                                    <SpacingBox mb={4}>
                                      <Field name="quantity">
                                        {({ form, field }) => (
                                          <LiquidQuantityInput
                                            {...field}
                                            size="large"
                                            filled
                                            onChange={(event) =>
                                              handleQuantityChange(
                                                event,
                                                form,
                                                field
                                              )
                                            }
                                            min={1}
                                            onBlur={() => {
                                              if (field.value < 1) {
                                                form.setFieldValue(
                                                  field.name,
                                                  1
                                                )
                                              }
                                            }}
                                            disabled={isSubmitting}
                                            inputProps={{
                                              inputMode: 'numeric',
                                            }}
                                            error={Boolean(hasMoqError)}
                                          />
                                        )}
                                      </Field>
                                    </SpacingBox>

                                    {showPreferredDealerSection && (
                                      <SpacingBox mb={6}>
                                        <PreferredDealerSection
                                          customerPricingResponse={
                                            customerPricingResponse
                                          }
                                          materialPricing={
                                            countryData?.materialPricing
                                          }
                                          selectCallback={
                                            handlePriceUpdateForDealer
                                          }
                                          setFieldValue={setFieldValue}
                                          values={values}
                                        />
                                      </SpacingBox>
                                    )}
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      color="secondary"
                                      disabled={isSubmitting || hasMoqError}
                                      size="large"
                                      fullWidth
                                      id="mobile-p-and-a-add-to-cart"
                                    >
                                      <FormattedMessage
                                        id="ADD_TO_CART"
                                        defaultMessage="Add to Cart"
                                      />
                                    </Button>
                                  </>
                                ) : null}
                              </>
                            )}

                            {selectedMaterial.isBuyNow &&
                            !hideAddToCartForPrepackItems ? (
                              <SpacingBox mt={4}>
                                <BuyNowButton
                                  materialNumber={values.materialNumber}
                                  quantity={values.quantity}
                                  gaType="search results page"
                                />
                              </SpacingBox>
                            ) : null}

                            {!hideAddToCartForPrepackItems ? (
                              <SpacingBox mt={4}>
                                <AddToListButton
                                  quantity={values.quantity}
                                  materialNumber={
                                    selectedMaterial.materialNumber
                                  }
                                  materialId={selectedMaterial.materialId}
                                  type={selectedMaterial.type}
                                  brand={selectedMaterial?.brand}
                                  productName={
                                    selectedMaterial?.materialDescription
                                  }
                                  productId={selectedMaterial?.product}
                                />
                              </SpacingBox>
                            ) : null}

                            {!!renderSecondaryAction && renderSecondaryAction()}
                          </div>
                        )
                      }
                      return null
                    }
                  )
                ) : (
                  <>
                    {/* Product SKU Selecter */}
                    <SpacingBox mb={1}>
                      <label htmlFor="TEMP">
                        <FormattedMessage {...messages.SKU} />
                      </label>
                    </SpacingBox>
                    <SpacingBox mb={4}>
                      <Field name="materialNumber">
                        {({ form, field, meta }) => (
                          <LiquidSelectAdapter
                            field={field}
                            meta={meta}
                            form={form}
                            size="large"
                            onChange={(event) => {
                              field.onChange(event)
                              form.setFieldValue('quantity', 1)
                              handleSkuChange(event.target.value)
                            }}
                            disabled={
                              isSubmitting || materialPricing.length < 2
                            }
                            options={materialPricing.map((material) => ({
                              label: material.materialNumber,
                              value: material.materialNumber,
                            }))}
                          />
                        )}
                      </Field>
                    </SpacingBox>

                    <div className={classes.materialInfo}>
                      <HandleMarkup value={selectedMaterial.packageSize} />
                    </div>

                    {!isInquire && <DisplayPrice material={selectedMaterial} />}

                    {/* promo bundle button */}
                    <SpacingBox mt={2}>
                      {selectedMaterial.isPBAvailable ? (
                        <PromoBundleButton
                          materialNumber={selectedMaterial.materialNumber}
                          brand={selectedMaterial.brand}
                          productName={productName ?? ''}
                          erp_type={erpType}
                        />
                      ) : null}
                    </SpacingBox>

                    {/* availability */}
                    <SpacingBox mb={6} mt={4}>
                      <TrackedAvailabilityMessageProvider
                        {...ctx}
                        brand={selectedMaterial.brand}
                        item={selectedMaterial.materialNumber}
                        pricing={selectedMaterial}
                      >
                        <MaterialAvailabilityPreview
                          material={selectedMaterial}
                          canAddToCart={canAddToCart}
                          gaDetailsClick={handleGAdetailsClick}
                        />
                      </TrackedAvailabilityMessageProvider>
                    </SpacingBox>

                    {/* shipping messages */}
                    <ShippingMessages material={selectedMaterial} />
                    {/* ExpiryDate */}
                    <ExpiryDate
                      expirationDate={selectedMaterial?.expirationDate}
                      displayColon
                      displayInline
                      outerClassName={classes.expiryDate}
                    />
                    {/* minimum Order Quantity */}
                    {selectedMaterial?.minOrderQuantity &&
                    selectedMaterial?.minOrderQuantity > 1 ? (
                      <div
                        className={clsx(
                          classes.minimumQuantity,
                          hasMoqError && classes.minimumQuantityError
                        )}
                      >
                        <FormattedMessage
                          {...messages.MINIMUM_ORDER_QUANTITY}
                        />
                        {selectedMaterial?.minOrderQuantity}
                      </div>
                    ) : null}

                    {!!isInquire ? (
                      // Inquire messages
                      <InquireMessages contactInfo={contactInfo} />
                    ) : (
                      <>
                        {canAddToCart && !hideAddToCartForPrepackItems ? (
                          <>
                            <SpacingBox mb={4}>
                              <Field name="quantity">
                                {({ form, field }) => (
                                  <LiquidQuantityInput
                                    {...field}
                                    size="large"
                                    filled
                                    onChange={(event) =>
                                      handleQuantityChange(event, form, field)
                                    }
                                    min={1}
                                    onBlur={() => {
                                      if (field.value < 1) {
                                        form.setFieldValue(field.name, 1)
                                      }
                                    }}
                                    disabled={isSubmitting}
                                    inputProps={{
                                      inputMode: 'numeric',
                                    }}
                                    error={Boolean(hasMoqError)}
                                  />
                                )}
                              </Field>
                            </SpacingBox>

                            {showPreferredDealerSection && (
                              <SpacingBox mb={6}>
                                <PreferredDealerSection
                                  customerPricingResponse={
                                    customerPricingResponse
                                  }
                                  materialPricing={materialPricing}
                                  selectCallback={handlePriceUpdateForDealer}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                />
                              </SpacingBox>
                            )}
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                              disabled={isSubmitting || hasMoqError}
                              size="large"
                              fullWidth
                              id="mobile-p-and-a-add-to-cart"
                            >
                              <FormattedMessage
                                id="ADD_TO_CART"
                                defaultMessage="Add to Cart"
                              />
                            </Button>
                          </>
                        ) : null}
                      </>
                    )}

                    {selectedMaterial.isBuyNow &&
                    !hideAddToCartForPrepackItems ? (
                      <SpacingBox mt={4}>
                        <BuyNowButton
                          materialNumber={values.materialNumber}
                          quantity={values.quantity}
                          marketplace={
                            selectedMaterial.catalogType ===
                            CatalogType.Marketplace
                          }
                          gaType="search results page"
                        />
                      </SpacingBox>
                    ) : null}

                    {!hideAddToCartForPrepackItems ? (
                      <SpacingBox mt={4}>
                        <AddToListButton
                          quantity={values.quantity}
                          materialNumber={selectedMaterial.materialNumber}
                          materialId={selectedMaterial.materialId}
                          type={selectedMaterial.type}
                          brand={selectedMaterial?.brand}
                          productName={selectedMaterial?.materialDescription}
                          productId={selectedMaterial?.product}
                        />
                      </SpacingBox>
                    ) : null}

                    {!!renderSecondaryAction && renderSecondaryAction()}
                  </>
                )}
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default MobileProductPriceAvailability
