import React, { FC, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { MaterialAvailabilityKey, CatalogType } from '@src/types/graphql-types'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import CheckMarkCircleIcon from '@src/icons/CheckMarkCircleIcon'
import { FormattedDate, FormattedMessage } from 'react-intl'
import messages from '@utils/MessageSets/p&aDynamicMessages'
import MaterialAvailabilityModal from '@src/components/MaterialAvailabilityModal/MaterialAvailabilityModal'
import { Box, Typography } from '@material-ui/core'
import AvailabilityOverwriteMessage from '@src/components/AvailabilityOverwriteMessage'
import { ShippingTypes } from '@src/utils/searchUtils'
import ShippingDetails from '@src/components/MaterialAvailabilityDisplay/ShippingDetails'
import WarningCircleIcon from '@src/icons/WarningCircleIcon'
import { TrackedAvailabilityMessage } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import { useDecision } from '@optimizely/react-sdk'
import { availableWithinFiveDays } from '@src/utils/availableWithinFiveDays'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  availableIcon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
  },
  shipText: {
    marginRight: theme.spacing(1),
    verticalAlign: 'top',
  },
  shipFromLink: {
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    verticalAlign: 'top',
  },
  from: {
    lineHeight: 1,
    margin: theme.spacing(0, 1),
  },
  shipIcon: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: theme.spacing(1),
    '& svg': {
      width: theme.typography.pxToRem(30),
    },
    cursor: 'pointer',
  },
  availabilityIcon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },
  warningText: {
    display: 'flex',
    color: theme.palette.error.main,
    alignItems: 'center',
  },
  messageText: {
    fontSize: theme.typography.pxToRem(14),
  },
  delayed: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface MaterialAvailabilityPreviewProps {
  material: ValidMaterialPricingDetailFragment
  canAddToCart: boolean
  countryCode?: string
  hideDetails?: boolean
  gaDetailsClick?: () => void
  gaSection?: string
  gaComponent?: string
}

const MaterialAvailabilityPreview: FC<MaterialAvailabilityPreviewProps> = ({
  material,
  canAddToCart,
  countryCode,
  hideDetails,
  gaDetailsClick,
  gaSection,
  gaComponent,
}) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [flagData] = useDecision('wabt-210')
  const availableQtyInStock = material?.availableQtyInStock
  // Need to check for null value explicitly as availableQtyInStock can be 0
  const hasAvailableQtyInStock = availableQtyInStock !== null
  const isMarketplace = material.catalogType === CatalogType.Marketplace
  // return the primary availability if it exists, otherwise return the first availability
  const availability =
    material?.availabilities?.find((item) => item?.messageType === 'primary') ??
    material?.availabilities?.[0]
  const showAvailability = (
    e:
      | React.MouseEvent<EventTarget & HTMLSpanElement>
      | React.KeyboardEvent<EventTarget & HTMLSpanElement>
  ) => {
    e.preventDefault()
    gaDetailsClick && gaDetailsClick()
    setIsModalOpen(true)
  }

  const handleOnModalClose = () => {
    setIsModalOpen(false)
  }

  const renderAvailability = (availability) => {
    let availabilityMessage: JSX.Element

    switch (true) {
      case !!availability?.availabilityOverwriteMessage:
        availabilityMessage = (
          <AvailabilityOverwriteMessage
            availabilityOverwrite={availability.availabilityOverwriteMessage}
          />
        )
        break
      case !!hasAvailableQtyInStock:
        availabilityMessage = (
          <div className={classes.warningText}>
            <WarningCircleIcon
              className={classes.availabilityIcon}
              color="warning"
            />
            <TrackedAvailabilityMessage
              id="DEPLETED_PRODUCTS_QTY_FEW_LEFT_IN_STOCK"
              defaultMessage="{availableQtyInStock} left in stock"
              values={{ availableQtyInStock }}
              availability={availability}
            />
            {availability?.displayFromLink && !hideDetails ? (
              <>
                <span
                  className={classes.shipFromLink}
                  onClick={showAvailability}
                  onKeyPress={showAvailability}
                  tabIndex={0}
                  role="button"
                >
                  <FormattedMessage id="DETAILS" defaultMessage="Details" />
                </span>
                {isModalOpen && (
                  <MaterialAvailabilityModal
                    isModalOpen={isModalOpen}
                    onModalClose={() => handleOnModalClose()}
                    material={material}
                    canAddToCart={canAddToCart}
                    availableQty={availableQtyInStock}
                    countryCode={countryCode}
                  />
                )}
              </>
            ) : null}
          </div>
        )
        break
      case availability?.key === MaterialAvailabilityKey.OnlyFewLeftInStock:
        const { quantity } = availability ?? {}
        availabilityMessage = (
          <span className={classes.root}>
            <div className={classes.availableIcon}>
              <CheckMarkCircleIcon color="success" fontSize="inherit" />
            </div>
            <div>
              <TrackedAvailabilityMessage
                id="FEW_LEFT_IN_STOCK"
                defaultMessage="{quantity} left in stock (more on the way)"
                values={{ quantity }}
                availability={availability}
              />
              {availability?.displayFromLink && !hideDetails ? (
                <>
                  <span
                    className={classes.shipFromLink}
                    onClick={showAvailability}
                    onKeyPress={showAvailability}
                    tabIndex={0}
                    role="button"
                  >
                    <FormattedMessage id="DETAILS" defaultMessage="Details" />
                  </span>
                  {isModalOpen && (
                    <MaterialAvailabilityModal
                      isModalOpen={isModalOpen}
                      onModalClose={() => handleOnModalClose()}
                      material={material}
                      canAddToCart={canAddToCart}
                      availableQty={availableQtyInStock}
                      countryCode={countryCode}
                    />
                  )}
                </>
              ) : null}
              {material?.shipsToday && !hideDetails && (
                <ShippingDetails
                  type={ShippingTypes.SameDay}
                  className={classes.shipIcon}
                  gaSection={gaSection}
                  gaComponent={gaComponent}
                  material={material}
                />
              )}
            </div>
          </span>
        )
        break
      case availability?.key === MaterialAvailabilityKey.UnknownAvailability:
        availabilityMessage = (
          <span className={classes.root}>
            <div className={classes.availableIcon}>
              <CheckMarkCircleIcon color="warning" fontSize="inherit" />
            </div>
            <div>
              <TrackedAvailabilityMessage
                id="AVAILABILITY_UNKNOWN"
                defaultMessage="Availability Unknown"
                availability={availability}
              />
            </div>
          </span>
        )
        break
      case availability?.key ===
        MaterialAvailabilityKey.FulfilmentDeliveryDelayed:
        availabilityMessage = (
          <span className={classes.root}>
            <div className={classes.availableIcon}>
              <CheckMarkCircleIcon color="warning" fontSize="inherit" />
            </div>
            <p>
              <TrackedAvailabilityMessage
                id="FULFILMENT_DELIVERY_DELAYED"
                defaultMessage="Fulfilment and delivery delayed"
                availability={availability}
              />
            </p>
          </span>
        )
        break
      case availability?.key === MaterialAvailabilityKey.OutOfStockKey:
        availabilityMessage = !hideDetails ? (
          <TrackedAvailabilityMessage
            id="OUT_OF_STOCK_KEY"
            defaultMessage="We apologize but fulfillment and delivery of this product is delayed. We are working to minimize these delays as quickly as possible."
            availability={availability}
          />
        ) : (
          <Box className={classes.delayed}>
            <WarningCircleIcon
              className={classes.availabilityIcon}
              color="warning"
            />
            <TrackedAvailabilityMessage
              id="FULFILMENT_DELIVERY_DELAYED"
              defaultMessage="Fulfilment and delivery delayed"
              availability={availability}
            />
          </Box>
        )
        break
      case availability?.key === MaterialAvailabilityKey.ContactForAvailability:
        availabilityMessage = (
          <span className={classes.root}>
            <div className={classes.availableIcon}>
              <CheckMarkCircleIcon color="warning" fontSize="inherit" />
            </div>
            <div>
              <TrackedAvailabilityMessage
                id="CONTACT_FOR_AVAILABILITY"
                defaultMessage="Please contact Customer Service for Availability"
                availability={availability}
              />
            </div>
          </span>
        )
        break

      case availability?.key ===
        MaterialAvailabilityKey.CheckCartForAvailability:
        availabilityMessage = (
          <span className={classes.root}>
            <div>
              <TrackedAvailabilityMessage
                id="CHECK_CART_FOR_AVAILABILITY"
                defaultMessage="Check Cart for Availability"
                availability={availability}
              />
            </div>
          </span>
        )
        break

      default:
        if (!availability?.date) return null

        availabilityMessage = (
          <span className={classes.root} data-optimizely={availability?.key}>
            <div className={classes.availableIcon}>
              <CheckMarkCircleIcon color="success" fontSize="inherit" />
            </div>
            <Typography variant="body2" component="p">
              <>
                {availableWithinFiveDays(availability) && flagData?.enabled ? (
                  <span className={classes.shipText}>
                    <TrackedAvailabilityMessage
                      id="IN_STOCK"
                      defaultMessage="In Stock"
                      availability={availability}
                    />
                  </span>
                ) : (
                  <>
                    <span className={classes.shipText}>
                      <TrackedAvailabilityMessage
                        {...messages[availability?.key]}
                        availability={availability}
                      />
                    </span>
                    <FormattedDate
                      value={new Date(availability.date)}
                      year="numeric"
                      month="long"
                      day="2-digit"
                      timeZone="UTC"
                    />
                  </>
                )}
              </>

              {availability?.displayFromLink && !hideDetails ? (
                <>
                  <span
                    className={classes.shipFromLink}
                    onClick={showAvailability}
                    onKeyPress={showAvailability}
                    tabIndex={0}
                    role="button"
                  >
                    <FormattedMessage id="DETAILS" defaultMessage="Details" />
                  </span>
                  {isModalOpen && (
                    <MaterialAvailabilityModal
                      isModalOpen={isModalOpen}
                      onModalClose={() => handleOnModalClose()}
                      material={material}
                      canAddToCart={canAddToCart}
                      countryCode={countryCode}
                    />
                  )}
                </>
              ) : availability?.plantLoc &&
                !flagData.enabled &&
                !isMarketplace ? (
                <>
                  <Typography component="span" className={classes.from}>
                    <TrackedAvailabilityMessage
                      id="FROM"
                      defaultMessage="From"
                      availability={availability}
                    />
                  </Typography>
                  {availability.plantLoc}
                </>
              ) : null}
              {material?.shipsToday && !hideDetails && (
                <ShippingDetails
                  type={ShippingTypes.SameDay}
                  className={classes.shipIcon}
                  gaSection={gaSection}
                  gaComponent={gaComponent}
                  material={material}
                />
              )}
            </Typography>
          </span>
        )
    }
    return availabilityMessage
  }

  return renderAvailability(availability)
}

export default MaterialAvailabilityPreview
