import React from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import {
  MaterialAvailability,
  MaterialAvailabilityKey,
} from '@src/types/graphql-types'
import AvailabilityOverwriteMessage from '@src/components/AvailabilityOverwriteMessage'
import messages from '@utils/messages'
import CheckMarkCircleIcon from '@src/icons/CheckMarkCircleIcon'
import ErrorIcon from '@material-ui/icons/Error'
import { TrackedAvailabilityMessage } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessage'
import { availableWithinFiveDays } from '@src/utils/availableWithinFiveDays'
import { useDecision } from '@optimizely/react-sdk'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    paddingInlineStart: 0,
    transition: 'color 250ms',
  },
  availabilityItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    borderTop: 'none',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  availableIcon: {
    fontSize: 16,
    marginRight: theme.spacing(2),
    display: 'flex',
  },
  disabled: {
    color: theme.palette.grey[400],
    '& svg': {
      fill: theme.palette.grey[400],
    },
  },
}))

interface Props {
  availabilities: MaterialAvailability[]
  updateAvailable: boolean
  className?: string
  hideQuantity?: boolean
  hidePlantLoc?: boolean
}

export const AvailabilityErrorMessage: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const classes = useStyles()

  return (
    <ol className={clsx(classes.root, className)}>
      <li className={classes.availabilityItem}>
        <div className={classes.availableIcon}>
          <ErrorIcon color="error" fontSize="inherit" />
        </div>
        <p>{children}</p>
      </li>
    </ol>
  )
}

const AvailabilityMessages: React.FC<Props> = ({
  availabilities,
  updateAvailable,
  className,
  hideQuantity,
  hidePlantLoc,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const [flagData] = useDecision('wabt-210')

  return (
    <ol
      className={clsx(
        classes.root,
        className,
        updateAvailable && classes.disabled
      )}
    >
      {availabilities.map((availability, index) => {
        const {
          key,
          availabilityOverwriteMessage,
          messageType,
          date,
          quantity,
          plantLoc,
        } = availability

        if (
          availabilityOverwriteMessage?.messageValue &&
          messageType === 'secondary'
        ) {
          return (
            <li key={index} className={classes.availabilityItem}>
              <AvailabilityOverwriteMessage
                availabilityOverwrite={availabilityOverwriteMessage}
              />
            </li>
          )
        }

        if (!date || !quantity) return null

        switch (key) {
          case MaterialAvailabilityKey.OutOfStockKey:
            return (
              <li key={index} className={classes.availabilityItem}>
                <p>
                  <TrackedAvailabilityMessage
                    id="OUT_OF_STOCK_KEY"
                    defaultMessage="We apologize but fulfillment and delivery of this product is delayed. We are working to minimize these delays as quickly as possible."
                    availability={availability}
                  />
                </p>
              </li>
            )

          case MaterialAvailabilityKey.UnknownAvailability:
          case MaterialAvailabilityKey.ContactForAvailability:
          case MaterialAvailabilityKey.FulfilmentDeliveryDelayed:
            return (
              <li key={index} className={classes.availabilityItem}>
                <div className={classes.availableIcon}>
                  <CheckMarkCircleIcon color="warning" fontSize="inherit" />
                </div>
                <p>
                  <TrackedAvailabilityMessage
                    id={key}
                    defaultMessage={
                      key === MaterialAvailabilityKey.UnknownAvailability
                        ? 'Availability Unknown'
                        : key === MaterialAvailabilityKey.ContactForAvailability
                          ? 'Please contact Customer Service for Availability'
                          : 'Fulfilment and delivery delayed'
                    }
                    availability={availability}
                  />
                </p>
              </li>
            )

          case MaterialAvailabilityKey.CheckCartForAvailability:
            return (
              <li key={index} className={classes.availabilityItem}>
                <p>
                  <TrackedAvailabilityMessage
                    id="CHECK_CART_FOR_AVAILABILITY"
                    defaultMessage="Check Cart for Availability"
                    availability={availability}
                  />
                </p>
              </li>
            )

          default:
            if (messageType === 'secondary') {
              return (
                <li key={index} className={classes.availabilityItem}>
                  <div className={classes.availableIcon}>
                    <CheckMarkCircleIcon color="success" fontSize="inherit" />
                  </div>
                  {availableWithinFiveDays(availability) && flagData.enabled ? (
                    <span>
                      <TrackedAvailabilityMessage
                        id="IN_STOCK"
                        defaultMessage="In Stock"
                        availability={availability}
                      />
                    </span>
                  ) : (
                    <p>
                      {!hideQuantity &&
                      (quantity > 0 || availabilities.length > 0)
                        ? `${quantity} `
                        : null}
                      <TrackedAvailabilityMessage
                        availability={availability}
                        {...messages[
                          key === MaterialAvailabilityKey.EstimatedDeliveryOn
                            ? 'AVAILABILITY_ESTIMATED_DELIVERY_ON'
                            : key
                        ]}
                      />{' '}
                      <FormattedDate
                        value={new Date(date)}
                        year="numeric"
                        month="long"
                        day="2-digit"
                        timeZone="UTC"
                      />
                      {!hidePlantLoc && plantLoc && (
                        <>
                          {` ${intl.formatMessage(messages.PRODUCT_AVAILABILITY_FROM)} `}
                          <span>{plantLoc}</span>
                        </>
                      )}
                    </p>
                  )}
                </li>
              )
            }
            return null
        }
      })}
    </ol>
  )
}

export default AvailabilityMessages
