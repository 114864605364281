import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { localStorage } from 'react-storage'
import getConfig from 'next/config'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { SubstanceProductFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import { determineCatalogType } from '@utils/searchUtils'
import { MAX_COMPARE_SIZE, useCompareProducts } from '@utils/useCompareProducts'
import messages from '../messages'
import SDSLink from '@src/components/SDSLink'
import HandleMarkup from '@src/components/HandleMarkup'
import MapAttributeValues from '@src/components/MapAttributeValues'
import ProductFeaturesBadges from '@src/components/ProductFeaturesBadges'
import ProductPriceAvailability from '@src/components/ProductPriceAvailability'
import ProductPaMessage, {
  ProductPaMessageDisplayType,
} from '@src/components/ProductPriceAvailability/ProductPaMessage'
import { AemAdditionalColumns } from '@src/aem-content/components/DynamicProductTable'
import {
  determinePAForAddtoCart,
  sendProductClickEvent,
} from '@src/utils/analytics'
import ShowMoreLessButton from '@src/components/ShowMoreLessButton'
import DownloadIcon from '@src/icons/DownloadIcon'
import LiquidCheckbox from '@src/components/LiquidCheckbox'
import ProductLink, { ProductLinkType } from '@src/components/ProductLink'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import { useRouter } from '@src/routes'
import ViewPricingLink from '@src/components/ProductPricingModal/ViewPricingLink'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import GreenBadge from '@src/components/GreenBadge/'

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

const useStyles = makeStyles((theme: Theme) => {
  return {
    tableRow: {
      height: '2.5rem',
      '& .MuiTableCell-body': {
        padding: '.2rem .6rem',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    productImageContainer: {
      height: '3.2rem',
      width: '3.2rem',
      display: 'flex',
      alignItems: 'center',
    },
    productImage: {
      height: 'auto',
      width: '100%',
    },
    productName: {
      paddingTop: theme.spacing(1),
    },
    productNumberContainer: {
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      paddingRight: '0 !important',
      fontWeight: theme.typography.fontWeightMedium,
    },
    productDescription: {
      maxWidth: '18rem',
    },
    productDescriptionName: {
      wordBreak: 'break-word',
      fontWeight: theme.typography.fontWeightMedium,
      paddingRight: '0.5rem',
    },
    breakWord: {
      wordBreak: 'break-word',
    },
    productDescLink: {
      wordBreak: 'break-word',
      color: theme.palette.common.black,
      fontWeight: 'normal',
    },
    hideBelowMd: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    hideOnMobile: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    sdsTrigger: {
      display: 'block', // Aligns icon better with rest of row by hiding whitespace
    },
    clearBottomBorder: {
      '& > *': {
        borderBottomColor: 'transparent',
      },
    },
    tableRowExpanded: {
      backgroundColor: theme.palette.background.grey,
    },

    // Prevents the table's columns from changing widths when the expand/hide button
    // changes its text. Allows the column to take the minimum amount of space needed
    // no matter what the translated strings end up being.
    expandHideSpacer: {
      height: 0,
      visibility: 'hidden',
    },
  }
})

interface ProductProps {
  product: SubstanceProductFieldsFragment
  displayBadgesOnProduct: boolean
  focus: string
  isShowBrand?: boolean // aem props
  isShowImage?: boolean // aem prop
  isStaticContent?: boolean // aem prop
  additionalColumns?: AemAdditionalColumns[] // aem prop
  productIsAntibody: boolean
  productIsSyringeFilter: boolean
  displayViewLink?: boolean
  substanceId?: string
  substancePosition?: number
  isShowDescriptionColumn?: boolean
}

// This will be removedc once the srpKeyAttributes feature flag is removed
const attributeValues = {
  CLONALITY: { key: 'clonality-clone_number.default' },
  SPECIES_REACTIVITY: { key: 'species reactivity.reactivity' },
  PORE_SIZE: { key: 'pore size.default' },
  DIAMETER: { key: 'composite dimension.default', label: 'diam.' },
  STERILITY: { key: 'sterilization.default' },
  MATERIAL: { key: 'material.default' },
}

// This component will need to be deleted the the srpKeyAttributes feature flag is removed
const AntibodyCells = ({ displayBadgesOnProduct, product, showGreenBadge }) => {
  const { attributes } = product
  const clonality = attributes.find(
    (att) => att.key === attributeValues.CLONALITY.key
  )
  const speciesReactivity = attributes.find(
    (attr) => attr.key === attributeValues.SPECIES_REACTIVITY.key
  )
  return (
    <>
      <TableCell>
        {clonality && <MapAttributeValues values={clonality.values} />}
      </TableCell>
      <TableCell>
        <HandleMarkup value={product.cardAttribute.application.join(', ')} />
      </TableCell>
      <TableCell>
        {speciesReactivity && (
          <MapAttributeValues values={speciesReactivity.values} />
        )}
      </TableCell>
      <TableCell>{product.cardAttribute.citationCount}</TableCell>
      {displayBadgesOnProduct ||
        (showGreenBadge && (
          <TableCell>
            {displayBadgesOnProduct && (
              <ProductFeaturesBadges features={product.features} />
            )}
            {showGreenBadge && <GreenBadge />}
          </TableCell>
        ))}
    </>
  )
}

// This component will need to be deleted the the srpKeyAttributes feature flag is removed
const SyringeCells = ({ displayBadgesOnProduct, product, showGreenBadge }) => {
  const { attributes } = product
  const poreSize = attributes.find(
    (attr) => attr.key === attributeValues.PORE_SIZE.key
  )
  const diameter = attributes.find(
    (attr) =>
      attr.key === attributeValues.DIAMETER.key &&
      attr.label === attributeValues.DIAMETER.label
  )
  const sterility = attributes.find(
    (attr) => attr.key === attributeValues.STERILITY.key
  )
  const materials = attributes.filter(
    (attr) => attr.key === attributeValues.MATERIAL.key
  )
  return (
    <>
      <TableCell data-testid="pdp-product-poreSize">
        {poreSize && poreSize.values.length && (
          <MapAttributeValues values={poreSize.values} />
        )}
      </TableCell>
      <TableCell data-testid="pdp-product-diameter">
        {diameter && <MapAttributeValues values={diameter.values} />}
      </TableCell>
      <TableCell data-testid="pdp-product-sterility">
        {sterility && <MapAttributeValues values={sterility.values} />}
      </TableCell>
      <TableCell data-testid="pdp-product-material">
        {materials &&
          materials.map((material) => (
            <MapAttributeValues key={material.key} values={material.values} />
          ))}
      </TableCell>
      {displayBadgesOnProduct ||
        (showGreenBadge && (
          <TableCell>
            {displayBadgesOnProduct && (
              <ProductFeaturesBadges features={product.features} />
            )}
            {showGreenBadge && <GreenBadge />}
          </TableCell>
        ))}
    </>
  )
}

const Product: React.FC<ProductProps> = ({
  product,
  displayBadgesOnProduct,
  focus,
  isShowBrand,
  isShowImage,
  isStaticContent,
  additionalColumns,
  productIsAntibody,
  productIsSyringeFilter,
  displayViewLink = true,
  substanceId,
  substancePosition,
  isShowDescriptionColumn,
}) => {
  const router = useRouter()
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [pricingExpanded, setPricingExpanded] = useState(false)
  const { currentUser } = useCurrentUser()
  const { compareProducts, addCompareProduct, removeCompareProduct } =
    useCompareProducts()
  const isCompared = compareProducts.some(
    ({ productKey, brandKey, catalogType }) =>
      productKey === product.productKey &&
      brandKey === product.brand.key &&
      catalogType === determineCatalogType(focus)
  )

  const querySearchTerm: string = localStorage?.getItem('searchedfor') ?? ''

  const handlePricingClick = (): void => {
    setPricingExpanded((curr) => !curr)
    sendPricingAvailabilityInteractionEvent({
      action: 'view p&a',
      section: substancePosition ? undefined : 'products',
      component: 'list',
      elementType: 'accordion',
      elementText: pricingExpanded ? 'hide' : 'expand',
      material: {
        materialDescription: product.name,
        brand: product.brand?.key,
        product: product.productNumber,
        materialNumber: product.productNumber,
      },
    })
  }

  const { paMessage } = product
  const productNumberLabel = product.productNumber.startsWith('CB_')
    ? formatMessage(messages.DETAILS)
    : product.productNumber
  const compareDisabled =
    !isCompared && compareProducts.length >= MAX_COMPARE_SIZE
  const productCompareValues = {
    productKey: product.productKey,
    productNumber: product.productNumber,
    brandKey: product.brand.key,
    images: product.images,
    catalogType: !!focus ? determineCatalogType(focus) : undefined,
  }
  // Display the link to view pricing for the new SRP P&A view if the isNewPNASrpEnabled feature flag is set
  // for the current country, and if debugging mode is not active (debug query parameter not present).
  // Users can revert to the old SRP view by adding '&debug=true' to the query parameters.

  const showViewPricingLink =
    currentUser?.metadata.isNewPNASrpEnabled &&
    !router.query.debug &&
    displayViewLink

  const handleProductClick = (): void =>
    sendProductClickEvent({
      product,
      searchTerm: querySearchTerm,
      substanceId,
      substancePosition,
    })

  const productDescription = product.legalNameSuffix || product.description

  const showGreenBadge = product.attributes?.find(
    (attr) => attr.key === 'greener alternative category.default'
  )

  return (
    <>
      <TableRow
        className={
          pricingExpanded
            ? clsx(
                classes.tableRow,
                classes.clearBottomBorder,
                classes.tableRowExpanded
              )
            : classes.tableRow
        }
        data-testid={`product-${product.name}`}
      >
        {isShowImage ? (
          <>
            <TableCell>
              <span className={classes.productImageContainer}>
                <img
                  className={classes.productImage}
                  src={product?.images[0]?.smallUrl}
                  alt={product?.images[0]?.altText || ''}
                />
              </span>
            </TableCell>
            <TableCell classes={{ root: classes.productNumberContainer }}>
              <ProductLink
                product={product}
                focus={focus}
                label={productNumberLabel}
                productDescriptionClass={classes.productName}
                productLinkType={ProductLinkType.NAME}
                productDescription={productDescription}
              />
            </TableCell>
          </>
        ) : (
          <>
            {!isStaticContent && (
              <TableCell className={classes.hideBelowMd}>
                <LiquidCheckbox
                  name={`${formatMessage(messages.COMPARE)}-${
                    product.productNumber
                  }`}
                  checked={isCompared}
                  onChange={(e) => {
                    e.target.checked
                      ? addCompareProduct(productCompareValues)
                      : removeCompareProduct(productCompareValues)
                  }}
                  aria-label={`${formatMessage(messages.COMPARE)} ${
                    product.productNumber
                  }`}
                  disabled={compareDisabled}
                />
              </TableCell>
            )}
            <TableCell classes={{ root: classes.productNumberContainer }}>
              <ProductLink
                product={product}
                focus={focus}
                label={productNumberLabel}
                onProductLinkClick={handleProductClick}
                productDescriptionClass={classes.productName}
                productLinkType={ProductLinkType.NAME}
                productDescription={productDescription}
              />
            </TableCell>
          </>
        )}
        {!featureFlags.srpKeyAttributes &&
          productIsAntibody &&
          product.cardAttribute && (
            <AntibodyCells
              displayBadgesOnProduct={displayBadgesOnProduct}
              showGreenBadge={showGreenBadge}
              product={product}
            />
          )}
        {!featureFlags.srpKeyAttributes && productIsSyringeFilter && (
          <SyringeCells
            displayBadgesOnProduct={displayBadgesOnProduct}
            showGreenBadge={showGreenBadge}
            product={product}
          />
        )}
        {featureFlags.srpKeyAttributes &&
          product?.keyAttributes &&
          product.keyAttributes.length > 0 && (
            <>
              {product.keyAttributes?.map((keyAttribute) => (
                <TableCell
                  data-testid={`pdp-product-${keyAttribute.label}`}
                  key={`${keyAttribute.key}_${keyAttribute.label}`}
                >
                  {keyAttribute.values.length > 0 ? (
                    <MapAttributeValues values={keyAttribute.values} />
                  ) : (
                    '—'
                  )}
                </TableCell>
              ))}
            </>
          )}
        {featureFlags.srpKeyAttributes &&
          product.cardCategory &&
          (displayBadgesOnProduct || showGreenBadge) && (
            <TableCell>
              {displayBadgesOnProduct && (
                <ProductFeaturesBadges features={product.features} />
              )}
              {showGreenBadge && <GreenBadge />}
            </TableCell>
          )}
        {!product.cardCategory && (
          <>
            {isShowDescriptionColumn && (
              <TableCell className={classes.productDescription} align="left">
                {isStaticContent && product.name && (
                  <HandleMarkup
                    value={product.name}
                    className={classes.productDescriptionName}
                  />
                )}
                <ProductLink
                  product={product}
                  focus={focus}
                  label={productDescription}
                  productDescriptionClass={classes.productDescLink}
                  productLinkType={ProductLinkType.DESCRIPTION}
                  productDescription={productDescription}
                />
              </TableCell>
            )}

            {isShowBrand && (
              <TableCell
                className={classes.hideOnMobile}
                style={{ color: product.brand.color || undefined }}
              >
                {product.brand.name}
              </TableCell>
            )}
            {additionalColumns &&
              additionalColumns.map(({ value }) => {
                const columnAttr = product.attributes.find(
                  (item) =>
                    item.key && item.key.toLowerCase() === value.toLowerCase()
                )
                return (
                  <TableCell key={value}>
                    <HandleMarkup value={columnAttr?.values?.join(', ')} />
                  </TableCell>
                )
              })}
            {displayBadgesOnProduct || showGreenBadge ? (
              <TableCell>
                {displayBadgesOnProduct && (
                  <ProductFeaturesBadges features={product.features} />
                )}
                {showGreenBadge && <GreenBadge />}
              </TableCell>
            ) : null}
          </>
        )}
        <TableCell>
          {!!product.sdsLanguages.length && (
            <SDSLink
              sdsLanguages={product.sdsLanguages}
              productNumber={product.sdsPnoKey}
              brandKey={product.brand.key}
              trigger={
                <DownloadIcon className={classes.sdsTrigger} color="primary" />
              }
            />
          )}
        </TableCell>
        <TableCell align="right">
          {showViewPricingLink ? (
            <ViewPricingLink
              product={product}
              substancePosition={substancePosition}
              focus={focus}
            />
          ) : (
            <>
              <ShowMoreLessButton
                expanded={pricingExpanded}
                setExpanded={handlePricingClick}
                collapsedText={formatMessage(messages.EXPAND)}
                expandedText={formatMessage(messages.HIDE)}
                data-testid={`srp-pricing-${product.productNumber}${product.brand.key}`}
              />
              <div className={classes.expandHideSpacer}>
                <ShowMoreLessButton
                  expanded={!pricingExpanded}
                  setExpanded={handlePricingClick}
                  collapsedText={formatMessage(messages.EXPAND)}
                  expandedText={formatMessage(messages.HIDE)}
                />
              </div>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableRowExpanded}>
        {pricingExpanded && (
          <TableCell colSpan={9}>
            {paMessage ? (
              <ProductPaMessage
                brandKey={product.brand.key}
                productNumber={product.productNumber}
                paMessage={paMessage}
                displayType={ProductPaMessageDisplayType.SRP}
              />
            ) : (
              <ProductPriceAvailability
                queryFocus={focus}
                productName={product.name}
                productNumber={product.productNumber}
                productDescription={product.description || ''}
                images={product.images}
                materialIds={product.materialIds}
                brandKey={product.brand.key}
                gaType={determinePAForAddtoCart(focus)}
                isMarketplace={product.isMarketplace}
                productAttributes={product.attributes}
                marketplaceSellerId={product.marketplaceSellerId || ''}
                marketplaceOfferId={product.marketplaceOfferId || ''}
                displaySellerName={product.displaySellerName || ''}
                displayPromotionalBundlesAnchor
                checkForPb
                productKey={product.productKey}
                erp_type={product.erp_type}
                isBBE={product.isBBE}
              />
            )}
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

export default Product
