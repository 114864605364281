import React, { useEffect, useMemo, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field, useFormikContext } from 'formik'
import { useIntl, FormattedMessage } from 'react-intl'
import { CircularProgress, Button } from '@material-ui/core'
import { noop } from 'lodash'
import { ProductLockupSimple } from '@src/components/ProductLockupSimple/ProductLockupSimple'
import ResponsiveModal, {
  ModalSizes,
  ResponsiveModalActions,
  ResponsiveModalBody,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import {
  Brand,
  CatalogImage,
  MaterialAvailabilityKey,
  CartItemType,
} from '@src/types/graphql-types'
import { useProductPaMessageQuery } from '@src/queries/ProductPAMessageQuery.generated'
import AddToCartButton from '@src/components/AddToCartButton'
import LiquidQuantityInputAdapter from '@src/components/LiquidQuantityInputAdapter'
import ProductPriceAvailability from '@src/components/ProductPriceAvailability'
import {
  InitialShipEstimate,
  ProductPriceAvailabilityFormBodyProps,
} from '@src/components/ProductPriceAvailability/ProductPriceAvailabilityFormBody'
import messages from '@utils/messages'
import LiquidFormLabel from '@src/components/LiquidFormLabel'
import { GTMEventCategory } from '@sial/common-utils'
import { setPaClipData } from '@utils/analytics/coreAnalytics'
import { AddToCartPagesEnum } from '@src/utils/analytics/enums'
import ProductPaMessage from '@src/components/ProductPriceAvailability/ProductPaMessage'
import { staticContent, useRouter } from '@src/routes'
import { useCurrentUser, SitePreference } from '@src/utils/useCurrentUser'
import { Abilities, useAbility } from '@src/utils/useAbility'
import {
  deObfuscateCatalogID,
  ObfuscatedCatalogID,
} from '@src/utils/catalogKeyIds'
import { determineCatalogType, ProductCardType } from '@src/utils/searchUtils'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import MultiActionButton from '@src/components/MultiActionButton'
import AddToListDialog from '@src/components/AddToListDialog'
import { useGetSharedListsQuery } from '@src/queries/SharedListQueries.generated'
import {
  sendPricingAvailabilityInteractionEvent,
  PricingAndAvailabilityPanelType,
} from '@src/utils/analytics/pricingAndAvailability'
import BuyNowButton from '@src/components/BuyNowButton'
import BlockedProductMessage from '@src/components/BlockedProductMessage/BlockedProductMessage'
import { MaterialNumberChips } from '../MaterialNumberChips/MaterialNumberChips'
import HidePriceMessage from '@src/components/HidePriceMessage'
import { Link } from '@src/components/Link'
import { useUserSession } from '@src/utils/useUserSession'
import MaterialAvailabilityDisplay from '../MaterialAvailabilityDisplay'
import MaterialPricingDisplay from '../MaterialPricingDisplay'
import { TrackedAvailabilityMessageProvider } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'
import ProductQuickLookDocuments from './ProductQuickLookDocuments'
import ProductQuickLookInfoList from './ProductQuickLookInfoList'
import { useProductErpType } from '@src/utils/useProductErpType'
import { useChinaUser } from '@src/utils/useChinaUser'
import SignInButton from '../SignInButton'

const useStyles = makeStyles((theme) => ({
  modalMainContent: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  quickLookWrapper: {
    padding: theme.spacing(8),
  },
  header: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0),
    },
  },
  previouslyPurchasedChip: {
    display: 'inline-block',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      margin: 0,
      position: 'absolute',
      top: 32,
      right: 32 + 16, // modal gutter + close icon
      paddingRight: theme.spacing(8),
    },
    '&:empty': { display: 'none' },
  },
  main: {
    flexGrow: 1,
    overflow: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
      borderTop: 'none',
      padding: 0,
      minHeight: 'fit-content',
    },
  },
  thumbnailOffset: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 88 + theme.spacing(6),
      marginTop: theme.spacing(6),
    },
  },
  loadingIndicator: {
    display: 'flex',
    color: theme.palette.grey[700],
    textTransform: 'uppercase',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(14),
      paddingBottom: theme.spacing(1.5),
      marginBottom: theme.spacing(4),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  priceWrapper: {
    paddingTop: theme.spacing(4),
  },
  reducedPriceWrapper: {
    margin: '-7px 0', // Negative margin added to prevent layout jumps
  },
  price: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
  },
  pricePromotionalWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: theme.spacing(3),
    },
  },
  promotionalCodeWrapper: {
    marginTop: theme.spacing(3),
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: 0,
      paddingTop: 4,
      marginLeft: theme.spacing(6),
    },
  },
  listPrice: {
    textDecoration: 'line-through',
    lineHeight: 1,
  },
  quantityField: {
    [theme.breakpoints.up('md')]: {
      width: 108,
    },
  },
  availabilityMessages: {
    minHeight: theme.typography.pxToRem(47), // creating a height so our loading component can match.
    marginBottom: theme.spacing(4),
    '& > *': {
      fontSize: theme.typography.pxToRem(16),
      borderTop: 'none',
      padding: 0,
      lineHeight: 1.4,
    },
    '& > * + *': {
      paddingTop: theme.spacing(1),
    },
  },
  actions: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  mainActions: {
    display: 'flex',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  subActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: theme.spacing(6, 0, 0, 0),
    '& > *': {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
    '& .MuiButton-root.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  },
  'checkAvailabilityButton.MuiButton-root': {
    // added to be able to fully left align the `check availability` button
    minWidth: 0,
  },
  supplementaryMessage: {
    marginTop: theme.spacing(1),
  },
  contactDealer: {
    marginTop: theme.spacing(4),
    fontSize: theme.typography.pxToRem(16),
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.typography.pxToRem(47), // matches height of loaded-in content
  },
}))

interface ExtendedProductPriceAvailabilityFormBodyProps
  extends ProductPriceAvailabilityFormBodyProps {
  promotionalCode?: string
}

export const PricingLoading = () => {
  const classes = useStyles()

  return (
    <div className={classes.loadingIndicator}>
      <CircularProgress size={20} thickness={5} />
      <span>
        <FormattedMessage {...messages.LOADING_PRICE_AND_AVAILABILITY} />
      </span>
    </div>
  )
}

const getPricingComponent = (
  onClose: () => void,
  initialMaterial?: string,
  promotionalCode?: string,
  showAddToList?: boolean,
  onAddToListClick?: (sku: string, qty: number) => void,
  carouselType?: ProductCardType,
  gaType?: AddToCartPagesEnum,
  isMarketplace = false,
  renderInModal?: boolean,
  requiresSignInForBestPrice?: boolean
): React.FC<ExtendedProductPriceAvailabilityFormBodyProps> =>
  function PricingComponent({
    materialPricing,
    values,
    isSubmitting,
    updateQuantity = noop,
  }) {
    const {
      userIsLoggedIn,
      getSitePreference,
      currentUser,
      isPublicOrderingCustomer,
      isQuoteRequester,
    } = useCurrentUser()
    const { authorized } = useAbility(Abilities.canAddToCart)
    const classes = useStyles()
    const { formatMessage } = useIntl()
    const { setValues } = useFormikContext<typeof values>()
    const hideAddToCartForPrepackItems = getSitePreference(
      SitePreference.HideAddToCartForPrepackItems
    )
    const { userSession } = useUserSession()

    const guestShowLocalDealer =
      currentUser?.__typename === 'GuestUser' && !authorized
    const loggedInShowLocalDealer =
      currentUser?.__typename === 'LoggedInUser' &&
      !currentUser?.metadata?.guestShoppingType &&
      !isPublicOrderingCustomer &&
      !isQuoteRequester

    const findMaterialInMaterialPricing = (
      materialNumber: string | undefined
    ) => {
      return materialPricing.find(
        (material) => material.materialNumber === materialNumber
      )
    }
    const defaultSelectedSKU: ValidMaterialPricingDetailFragment =
      findMaterialInMaterialPricing(initialMaterial) || materialPricing?.[0]
    const [selectedSKU, setSelectedSKU] = useState<string>(
      defaultSelectedSKU.materialNumber
    )
    const [selectedQty, setSelectedQty] = useState<number>(1)

    useEffect(() => {
      const quantity = Number(values[0]?.quantity) || 1
      setSelectedQty(quantity)
    }, [values])

    useEffect(() => {
      setValues((_values) => {
        const materialNumber =
          initialMaterial &&
          materialPricing.some((m) => m.materialNumber === initialMaterial)
            ? initialMaterial
            : _values[0]?.materialNumber

        return {
          0: {
            ..._values[0],
            materialNumber,
            quantity: materialPricing?.[0]?.quantity || 1,
          },
        }
      })
    }, [setValues, materialPricing])

    const lastCheckedQuantity = useRef(1)
    const [isUpdating, setIsUpdating] = useState(false)

    const selectedPricing = useMemo(() => {
      return findMaterialInMaterialPricing(selectedSKU)
    }, [materialPricing, selectedSKU])

    if (!selectedPricing) return null

    const { price, availabilities, hidePriceMessageKey } = selectedPricing
    const [availability] = availabilities || []
    const inquireForPAndA =
      availability?.key ===
      MaterialAvailabilityKey.InquireForPriceAndAvailability

    const handleSelectSKU = (materialNumber: string) => {
      setSelectedSKU(materialNumber)
      setValues((_values) => {
        return {
          0: {
            ..._values[0],
            materialNumber,
            quantity: Number(_values[0]?.quantity) || 1,
          },
        }
      })
    }

    return (
      <>
        <div>
          <LiquidFormLabel>
            <span className={classes.label}>
              <FormattedMessage {...messages.SKU} />
            </span>
          </LiquidFormLabel>
          <MaterialNumberChips
            materialPricing={materialPricing}
            selectedChip={selectedSKU}
            onSelect={handleSelectSKU}
          />
        </div>

        {hidePriceMessageKey ? (
          <HidePriceMessage msgKey={hidePriceMessageKey} />
        ) : (
          price != null && (
            <div className={classes.pricePromotionalWrapper}>
              {!inquireForPAndA && (
                <MaterialPricingDisplay
                  material={selectedPricing}
                  priceClass={classes.price}
                  listPriceClass={classes.listPrice}
                />
              )}

              {promotionalCode && (
                <div className={classes.promotionalCodeWrapper}>
                  <FormattedMessage
                    {...messages.USE_PROMOTIONAL_CODE}
                    values={{
                      promotionalCode: <strong>{promotionalCode}</strong>,
                    }}
                  />
                </div>
              )}
            </div>
          )
        )}
        <div className={classes.availabilityMessages}>
          {isUpdating ? (
            <div className={classes.loadingContainer}>
              <PricingLoading />
            </div>
          ) : (
            <TrackedAvailabilityMessageProvider
              source={gaType && `p&a modal - ${gaType}`}
              brand={selectedPricing.brand}
              item={selectedPricing.materialNumber}
              pricing={selectedPricing}
            >
              <MaterialAvailabilityDisplay
                canAddToCart={authorized}
                material={selectedPricing}
                initialShipEstimate={
                  <InitialShipEstimate
                    availability={availability}
                    quantity={selectedQty}
                  />
                }
                isDynamicProductCarousel
                isLoading={isUpdating}
                hideDetailsLink
                gaComponent={'modal'}
                gaSection={'quick view p&a'}
              />
            </TrackedAvailabilityMessageProvider>
          )}
        </div>

        {guestShowLocalDealer || loggedInShowLocalDealer ? (
          <div className={classes.contactDealer}>
            <FormattedMessage
              id="CONTACT_LOCAL_DEALER"
              defaultMessage="To order products, please {contactLink} your local dealer."
              values={{
                contactLink: (
                  <Link
                    {...staticContent.index({
                      path: '/collections/offices',
                      language: userSession.language,
                      country: userSession.country,
                    })}
                    passHref
                  >
                    <a rel="noopener" target="_blank">
                      <FormattedMessage id="CONTACT" defaultMessage="contact" />
                    </a>
                  </Link>
                ),
              }}
            />
          </div>
        ) : (
          <>
            {requiresSignInForBestPrice ? (
              <SignInButton size="medium" />
            ) : (
              authorized &&
              !hideAddToCartForPrepackItems &&
              !selectedPricing?.isBlockedProduct &&
              !inquireForPAndA && (
                <ResponsiveModalActions className={classes.actions}>
                  <div className={classes.mainActions}>
                    <Field
                      name="[0].quantity"
                      component={LiquidQuantityInputAdapter}
                      className={classes.quantityField}
                      min={1}
                    />
                    <div>
                      {showAddToList && userIsLoggedIn ? (
                        <MultiActionButton
                          primaryOption={{
                            label: formatMessage({
                              id: 'ADD_TO_CART',
                              defaultMessage: 'Add to Cart',
                            }),
                            renderButton: () => (
                              <AddToCartButton
                                dirty
                                isSubmitting={isSubmitting}
                                canAddToCart
                                hideAddToCartForPrepackItems={
                                  hideAddToCartForPrepackItems
                                }
                                size="large"
                              />
                            ),
                          }}
                          options={[
                            {
                              label: formatMessage(messages.ADD_TO_LIST),
                              onClick: () => {
                                onAddToListClick &&
                                  onAddToListClick(selectedSKU, selectedQty)
                              },
                            },
                          ]}
                        />
                      ) : (
                        <AddToCartButton
                          dirty
                          isSubmitting={isSubmitting}
                          canAddToCart
                          hideAddToCartForPrepackItems={
                            hideAddToCartForPrepackItems
                          }
                          size="large"
                          fullWidth
                        />
                      )}
                    </div>
                    {findMaterialInMaterialPricing(selectedSKU)?.isBuyNow && (
                      <BuyNowButton
                        materialNumber={selectedSKU || ''}
                        quantity={selectedQty}
                        onSuccess={onClose}
                        marketplace={isMarketplace}
                        gaType={gaType}
                      />
                    )}
                  </div>
                  <div className={classes.subActions}>
                    <Button
                      className={
                        classes['checkAvailabilityButton.MuiButton-root']
                      }
                      variant="text"
                      color="primary"
                      size="large"
                      disabled={selectedQty === lastCheckedQuantity.current}
                      onClick={async () => {
                        sendPricingAvailabilityInteractionEvent(
                          {
                            action: 'check product availability',
                            section: renderInModal
                              ? 'modal p&a'
                              : 'quick view p&a',
                            component: 'modal',
                            elementType: 'link',
                            elementText: 'check availability',
                            material: selectedPricing,
                          },
                          {
                            eventCategory:
                              GTMEventCategory.PricingAndAvailability,
                            eventAction: renderInModal
                              ? `check availability - ${
                                  carouselType
                                    ? PricingAndAvailabilityPanelType[
                                        carouselType
                                      ]
                                    : gaType
                                }`
                              : 'check availability',
                            eventLabel: renderInModal
                              ? selectedPricing?.materialNumber.toLowerCase()
                              : selectedPricing?.materialNumber,
                            eventInteractionType: 0,
                          }
                        )
                        if (lastCheckedQuantity.current === selectedQty) {
                          return
                        }
                        lastCheckedQuantity.current = selectedQty
                        setIsUpdating(true)
                        await updateQuantity(selectedQty)
                        setIsUpdating(false)
                      }}
                    >
                      <FormattedMessage
                        {...messages.CHECK_AVAILABILITY_BUTTON}
                      />
                    </Button>
                  </div>
                </ResponsiveModalActions>
              )
            )}
            {selectedPricing?.isBlockedProduct ? (
              <BlockedProductMessage />
            ) : null}
          </>
        )}
      </>
    )
  }

interface ProductQuickLookProps {
  open: boolean
  onClose: () => void
  image: Pick<CatalogImage, 'altText' | 'mediumUrl'>
  brand: Pick<Brand, 'key' | 'name' | 'color'>
  productKey: string
  productNumber: string
  name: string
  description?: string | null
  gaLabel?: string
  gaType?: AddToCartPagesEnum
  initialMaterial?: string
  promotionalCode?: string
  showAddToList?: boolean
  carouselType?: ProductCardType
  isMarketplace?: boolean
  marketplaceSellerId?: string
  erpType?: string[]
  renderInModal?: boolean
  renderDocuments?: boolean
  linearFormula?: string | null
  empiricalFormula?: string | null
  molecularWeight?: string | null
  casNumber?: string | null
  type?: string
}

const ProductQuickLook: React.FC<ProductQuickLookProps> = ({
  open,
  onClose,
  image,
  brand,
  productKey,
  productNumber,
  name,
  description,
  gaLabel,
  gaType,
  initialMaterial,
  promotionalCode,
  showAddToList,
  carouselType,
  isMarketplace = false,
  marketplaceSellerId,
  erpType,
  renderInModal = true,
  renderDocuments = false,
  linearFormula,
  empiricalFormula,
  casNumber,
  molecularWeight,
  type,
}) => {
  const classes = useStyles()
  const { userIsLoggedIn, userId } = useCurrentUser()
  const [materialToAddToList, setMaterialToAddToList] = useState('')
  const [quantityToAddToList, setQuantityToAddToList] = useState(1)
  const [modal, setModal] = useState<
    'price-and-availability' | 'add-to-list' | null
  >('price-and-availability')

  const handleOnAddToListClick = (materialNumber: string, qty: number) => {
    setMaterialToAddToList(materialNumber)
    setQuantityToAddToList(qty)
    setModal('add-to-list')
  }

  const { isPurpleProduct } = useProductErpType(erpType)
  const isChinaUser = useChinaUser()

  const requiresSignInForBestPrice =
    isPurpleProduct && isChinaUser && !userIsLoggedIn

  const Pricing = useMemo(
    () =>
      getPricingComponent(
        onClose,
        initialMaterial,
        promotionalCode,
        showAddToList,
        handleOnAddToListClick,
        carouselType,
        gaType,
        isMarketplace,
        renderInModal,
        requiresSignInForBestPrice
      ),
    [initialMaterial]
  )
  const router = useRouter()
  const { getSitePreference } = useCurrentUser()
  const orgId = getSitePreference(SitePreference.CatalogFilter) || undefined
  const { context = '', focus = '' } = router.query
  const catalog = deObfuscateCatalogID(context as ObfuscatedCatalogID)
  const catalogType = determineCatalogType(catalog)
  const { data: paMessageData, loading: paMessageLoading } =
    useProductPaMessageQuery({
      variables: {
        brandKey: brand.key,
        productKey,
        catalogType,
        orgId,
      },
    })
  const paMessage = paMessageData?.getProductDetail?.paMessage
  const isBBE = type === CartItemType.ThirdPartyProvider
  const materials = initialMaterial ?? ''
  useEffect(() => {
    setModal(open ? 'price-and-availability' : null)

    if (gaType) {
      sendPricingAvailabilityInteractionEvent(
        {
          action: 'view p&a',
          section: gaType,
          component: renderInModal ? 'carousel' : 'autosuggest',
          elementType: renderInModal ? 'button' : 'link',
          elementText: 'quick view',
          material: {
            materialDescription: name,
            brand: brand.key,
            product: productNumber,
          },
        },
        {
          eventCategory: GTMEventCategory.PricingAndAvailability.toLowerCase(),
          eventAction: renderInModal ? gaType : 'View P&A',
          eventLabel: productNumber,
          eventInteractionType: 0,
        }
      )
    }
  }, [productNumber, gaType, gaLabel, open, renderInModal])

  const { data: sharedListData, refetch: refetchLists } =
    useGetSharedListsQuery({
      fetchPolicy: 'cache-and-network',
      skip: !userIsLoggedIn,
      variables: {
        input: {
          perpage: 100, //HACK: Avoiding paginated results as not all are returned.
          sortcolumn: 'lastUpdatedDate',
        },
      },
    })

  const renderProductQuickLook = () => {
    return (
      <>
        <div className={classes.header}>
          <ProductLockupSimple
            image={image}
            brand={brand}
            productKey={productKey}
            productNumber={productNumber}
            name={name}
            description={description}
            showPreviouslyPurchasedChip
            isMarketplace={isMarketplace}
            marketplaceSellerId={marketplaceSellerId || undefined}
            onProductLinkClick={onClose}
            additionalInfo={
              (linearFormula ||
                empiricalFormula ||
                casNumber ||
                molecularWeight) && (
                <ProductQuickLookInfoList
                  focus={focus}
                  productNumber={productNumber}
                  casNumber={casNumber}
                  molecularWeight={molecularWeight}
                  linearFormula={linearFormula}
                  empiricalFormula={empiricalFormula}
                />
              )
            }
          />
        </div>
        <div className={classes.main}>
          <div className={classes.thumbnailOffset}>
            <TrackedAvailabilityMessageProvider
              source={gaType && `p&a modal - ${gaType}`}
              brand={brand.key}
              item={productNumber}
            >
              {paMessageLoading ? (
                <PricingLoading />
              ) : paMessage ? (
                <ProductPaMessage
                  brandKey={brand.key}
                  productNumber={productNumber}
                  paMessage={paMessage}
                />
              ) : (
                <>
                  <ProductPriceAvailability
                    productNumber={productNumber}
                    brandKey={brand.key}
                    LoadingComponent={PricingLoading}
                    PricingComponent={
                      paMessageLoading ? PricingLoading : Pricing
                    }
                    usePricingComponentOnMobile
                    condensed
                    onAddToCartResult={onClose}
                    gaType={gaType}
                    gaMapProducts={setPaClipData}
                    productKey={productKey}
                    isMarketplace={isMarketplace}
                    erp_type={erpType}
                    productName={name}
                    isBBE={isBBE}
                    materialIds={[materials]}
                  />
                  {renderDocuments && (
                    <ProductQuickLookDocuments
                      brand={brand}
                      productNumber={productNumber}
                    />
                  )}
                </>
              )}
            </TrackedAvailabilityMessageProvider>
          </div>
        </div>
      </>
    )
  }

  if (renderInModal) {
    return (
      <>
        <ResponsiveModal
          open={modal === 'price-and-availability'}
          onClose={() => {
            onClose()
            sendPricingAvailabilityInteractionEvent({
              action: 'close p&a',
              section: 'modal p&a',
              component: 'modal',
              elementType: 'icon',
              material: {
                materialDescription: name,
                brand: brand.key,
                product: productNumber,
              },
            })
          }}
          size={ModalSizes.Large}
        >
          <ResponsiveModalBody className={classes.modalMainContent}>
            {renderProductQuickLook()}
          </ResponsiveModalBody>
        </ResponsiveModal>
        {showAddToList && sharedListData?.getSharedLists && (
          <AddToListDialog
            open={modal === 'add-to-list'}
            onClose={() => {
              setModal(null)
              onClose()
            }}
            materialNumber={materialToAddToList}
            quantity={quantityToAddToList}
            lists={sharedListData.getSharedLists.sharedLists}
            refetchLists={refetchLists}
            userId={userId}
            carouselType={carouselType}
          />
        )}
      </>
    )
  } else {
    return (
      <div className={classes.quickLookWrapper}>{renderProductQuickLook()}</div>
    )
  }
}

export default ProductQuickLook
