import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Carousel, { ControlProps } from 'nuka-carousel'
import {
  Theme,
  ButtonBase,
  Container,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import CarouselArrowLeftIcon from '@icons/CarouselArrowLeftIcon'
import CarouselArrowRightIcon from '@icons/CarouselArrowRightIcon'
import { DynamicProductFragment } from '@src/fragments/DynamicProduct.generated'
import {
  ProductCardType,
  PRODUCT_CARD_TYPE_TEST_ID,
  PRODUCT_CARD_TYPE_TRANSLATED_TITLE,
  PRODUCT_CAROUSEL_TYPES,
} from '@utils/searchUtils'
import DynamicProductCard from './DynamicProductCard'
import {
  sendCarousalPromoTrackEvent,
  getCarousalVisibleCards,
  trackScrollingForProductCards,
  isRecommendedProductsVisibleListener,
  sendEmptyRecommendationEvent,
} from '@utils/analytics'
import { ProductCardTypeToPageMap } from '@utils/analytics/CategorizePage'
import useResponsiveSizes from '@utils/useResponsiveSizes'
import ProductQuickLook from '@src/components/ProductQuickLook/ProductQuickLook'
import { ProductCardProps } from './DynamicProductCardTypes'
import { sessionStorage } from 'react-storage'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    withoutControls: {
      padding: 0,
    },
    content: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(20),

      // Adds visible keyboard focus since the div element is tabbable (though not interactive)
      '& .slider-frame': {
        border: `2px solid transparent`,
        '&:focus-visible': {
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: theme.shape.borderRadius,
        },
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(8),
      },
    },
    contentInTab: {
      paddingTop: theme.spacing(4),
    },
    contentCondensed: {
      padding: 0,
    },
    title: {
      marginBottom: theme.spacing(8),
      '$contentCondensed &': {
        marginBottom: theme.spacing(4),
      },
    },
    navBtn: {},
    navBtnSmall: {
      height: 24,
      width: 24,
    },
    navBtnLarge: {
      height: 32,
      width: 32,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    navBtnLeft: {
      transform: 'translateX(-40px)',
    },
    mobileBottomControls: {
      width: 105,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      bottom: -44,
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    mobileBottomControlsHeroCard: {
      display: 'flex',
    },
    icon: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      width: '1em',
    },
    iconDisabled: {
      color: theme.palette.grey[500],
    },
    cartRecommended: {
      backgroundColor: theme.palette.background.grey,
      marginBottom: theme.spacing(20),
    },
    card: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(10),
      },
    },
    productHeroCardContent: {
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        touchAction: 'pan-y',
      },
      '& $productHeroTile button': {
        '@media (min-width: 900px) and (max-width: 1300px)': {
          whiteSpace: 'normal',
        },
      },
    },
    productHeroCardTitle: {
      margin: `${theme.spacing(
        0,
        0,
        theme.typography.pxToRem(40),
        0
      )} !important`,
      fontSize: `${theme.typography.pxToRem(18)} !important`,
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(
          0,
          0,
          theme.typography.pxToRem(24),
          3
        )} !important`,
      },
    },
    productHeroTile: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 4),
      },
    },
  }
})

const handlePrevious = (
  previousSlide: Function,
  testId: string,
  type: string
) => {
  //function to get the cards that are visible before calling the previousSlide.
  getCarousalVisibleCards(testId)
  previousSlide()
  // TODO: Analytics for the frequently purchased products are going to be done as part of a different ticket, so we need to skip them.
  // TODO: Analytics for Best Sellers are TBD
  //added a 50ms timeout to make sure the next product card is visible
  //before calling the GA tracking event.
  type !== ProductCardType.FrequentlyPurchased &&
    type !== ProductCardType.BestSellers &&
    setTimeout(() => {
      sendCarousalPromoTrackEvent(testId, type)
    }, 50)
}

const handleNext = (nextSlide: Function, testId: string, type: string) => {
  //function to get the cards that are visible before calling the nextSlide.
  getCarousalVisibleCards(testId)
  nextSlide()
  // TODO: Analytics for the frequently purchased products are going to be done as part of a different ticket, so we need to skip them.
  // TODO: Analytics for Best Sellers are TBD
  //added a 50ms timeout to make sure the next product card is visible
  //before calling the GA tracking event.
  type !== ProductCardType.FrequentlyPurchased &&
    setTimeout(() => {
      sendCarousalPromoTrackEvent(testId, type)
    }, 50)
}

interface ProductCarouselControlsConfig {
  testId: string
  type: ProductCardType
  productHeroCard?: boolean
}

export type ProductCarouselControlProps = Pick<
  ControlProps,
  | 'currentSlide'
  | 'slidesToShow'
  | 'slideCount'
  | 'previousSlide'
  | 'nextSlide'
  | 'slidesToScroll'
>

export const useProductCarouselControls = ({
  testId,
  type,
  productHeroCard,
}: ProductCarouselControlsConfig) => {
  const classes = useStyles()
  return {
    renderCenterLeftControls: (props: ProductCarouselControlProps) => {
      const disabled = props.currentSlide === 0
      return (
        <>
          {!productHeroCard && (
            <ButtonBase
              className={clsx(
                classes.navBtn,
                classes.navBtnLarge,
                classes.navBtnLeft
              )}
              disabled={disabled}
              onClick={() => handlePrevious(props.previousSlide, testId, type)}
              aria-label="previous"
            >
              <CarouselArrowLeftIcon
                className={clsx(classes.icon, {
                  [classes.iconDisabled]: disabled,
                })}
              />
            </ButtonBase>
          )}
        </>
      )
    },
    renderCenterRightControls: (props: ProductCarouselControlProps) => {
      const disabled =
        props.currentSlide + props.slidesToShow === props.slideCount ||
        props.slideCount < props.slidesToShow
      return (
        <>
          {!productHeroCard && (
            <ButtonBase
              disabled={disabled}
              className={clsx(classes.navBtn, classes.navBtnLarge)}
              onClick={() => handleNext(props.nextSlide, testId, type)}
              aria-label="next"
            >
              <CarouselArrowRightIcon
                className={clsx(classes.icon, {
                  [classes.iconDisabled]: props.slidesToScroll && disabled,
                })}
              />
            </ButtonBase>
          )}
        </>
      )
    },
    renderBottomCenterControls: (props: ProductCarouselControlProps) => {
      const previousDisabled = props.currentSlide === 0
      const nextDisabled =
        props.currentSlide + props.slidesToShow === props.slideCount ||
        props.slideCount < props.slidesToShow
      return (
        <div
          className={clsx(
            classes.mobileBottomControls,
            productHeroCard && classes.mobileBottomControlsHeroCard
          )}
        >
          <ButtonBase
            disabled={previousDisabled}
            onClick={() => handlePrevious(props.previousSlide, testId, type)}
            aria-label="previous"
            className={clsx(classes.navBtn, classes.navBtnSmall)}
          >
            <CarouselArrowLeftIcon
              className={clsx(classes.icon, {
                [classes.iconDisabled]: previousDisabled,
              })}
            />
          </ButtonBase>
          <Typography variant="body2">
            {props.currentSlide + 1} of {props.slideCount}
          </Typography>
          <ButtonBase
            disabled={nextDisabled}
            onClick={() => handleNext(props.nextSlide, testId, type)}
            aria-label="next"
            className={clsx(classes.navBtn, classes.navBtnSmall)}
          >
            <CarouselArrowRightIcon
              className={clsx(classes.icon, {
                [classes.iconDisabled]: props.slidesToScroll && nextDisabled,
              })}
            />
          </ButtonBase>
        </div>
      )
    },
  }
}

const DynamicProductCarousel: React.FC<ProductCardProps> = ({
  type,
  products,
  productSKU,
  withoutControls,
  slidesToShow,
  condensed,
  preloadCardImages,
  parentPageName,
  parentPageNameDetail,
  title,
  titleOverride,
  productHeroCard = false,
  isProductRecommendation = false,
  hideProductImg = false,
  displayDiscount,
  setRef,
}) => {
  const classes = useStyles()
  const getSize = useResponsiveSizes()
  const testId = PRODUCT_CARD_TYPE_TEST_ID[type]
  const carouselControls = useProductCarouselControls({
    testId,
    type,
    productHeroCard,
  })
  const isCartRecommended = type === ProductCardType.CartRecommendedProducts
  const numProducts = getSize({ xs: 1, sm: 2, md: 3, lg: 4 })
  const [quickAddProduct, setQuickAddProduct] =
    useState<DynamicProductFragment | null>(null)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')) === true

  useEffect(() => {
    const isProductCarouselType = PRODUCT_CAROUSEL_TYPES.includes(type)

    const handleProductCarouselTracking = () => {
      sessionStorage.setItem('DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT', {
        name: parentPageName,
        detail: parentPageNameDetail,
      })
    }

    if (products && products.length) {
      if (isProductCarouselType) {
        handleProductCarouselTracking()
      }
      if (type !== ProductCardType.FrequentlyPurchased) {
        trackScrollingForProductCards(type, products)
      }
    } else if (type !== ProductCardType.Related) {
      sendEmptyRecommendationEvent(`pdp > ${testId} > ${productSKU}`)
    }

    return () => {
      if (isProductCarouselType) {
        sessionStorage.removeItem('DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT')
      }
    }
  }, [type, products, parentPageName, parentPageNameDetail, testId, productSKU])

  useEffect(() => {
    //For custom products, the P&A is not available.
    //In this case, the recommended products are visible on the viewport without any scrolling needed.
    //So we need to call promo tracking event on page load.
    if (type === ProductCardType.Recommended && products?.length) {
      //added a timeout since the carousel is rendered a little later after the products become available.
      setTimeout(() => {
        isRecommendedProductsVisibleListener()
      }, 100)
    }
  }, [])

  if (!products || !products?.length) return null

  const renderComponentTitle = () => {
    if (titleOverride) {
      return titleOverride
    } else
      return (
        <Typography
          variant="h2"
          className={clsx(
            classes.title,
            productHeroCard && classes.productHeroCardTitle
          )}
        >
          {title ||
            (PRODUCT_CARD_TYPE_TRANSLATED_TITLE[type] ? (
              <FormattedMessage {...PRODUCT_CARD_TYPE_TRANSLATED_TITLE[type]} />
            ) : (
              type
            ))}
        </Typography>
      )
  }

  return (
    <div
      data-testid={testId}
      id={testId}
      className={clsx({
        [classes.cartRecommended]: isCartRecommended,
      })}
      ref={setRef && setRef('recProducts')}
    >
      <Container
        maxWidth="lg"
        className={clsx({
          [classes.withoutControls]: withoutControls,
        })}
      >
        <div
          className={clsx(
            classes.content,
            {
              [classes.contentCondensed]: condensed,
            },
            productHeroCard && classes.productHeroCardContent,
            isProductRecommendation && classes.contentInTab
          )}
        >
          {renderComponentTitle()}
          <Carousel
            slidesToScroll={
              productHeroCard && isDesktop ? 2 : slidesToShow || numProducts
            }
            slidesToShow={
              productHeroCard && isDesktop ? 2 : slidesToShow || numProducts
            }
            disableEdgeSwiping
            withoutControls={withoutControls}
            {...carouselControls}
          >
            {products.map((product, i) => (
              <DynamicProductCard
                key={i}
                index={i % numProducts}
                product={product}
                testId={testId}
                onViewPrice={() => setQuickAddProduct(product)}
                preloadCardImages={preloadCardImages}
                className={clsx(
                  classes.card,
                  productHeroCard && classes.productHeroTile
                )}
                type={type}
                hideProductImg={hideProductImg}
                displayDiscount={displayDiscount}
              />
            ))}
          </Carousel>
        </div>
      </Container>
      {quickAddProduct && (
        <ProductQuickLook
          {...quickAddProduct}
          image={quickAddProduct.images[0]}
          open
          onClose={() => setQuickAddProduct(null)}
          gaType={ProductCardTypeToPageMap[type]}
          showAddToList
          carouselType={type}
          erpType={quickAddProduct?.erp_type || []}
        />
      )}
    </div>
  )
}

export default DynamicProductCarousel
