import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApolloError } from 'apollo-boost'
import getConfig from 'next/config'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core'
import messages from '@src/routes/SearchResults/messages'
import { SubstanceProductFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import { sendSeeAllEvent } from '@utils/analytics'
import ShowMoreLessButton from '@src/components/ShowMoreLessButton'
import { AemAdditionalColumns } from '@src/aem-content/components/DynamicProductTable'
import Product from './Product'
import clsx from 'clsx'

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

type KeyAttributesProps = {
  keyAttributes: SubstanceProductFieldsFragment['keyAttributes']
}

export interface ProductTableProps {
  products: SubstanceProductFieldsFragment[]
  displayBadgesOnProduct: boolean
  focus: string
  error?: ApolloError
  maxRows?: number // aem prop
  isShowImage?: boolean // aem props
  isShowBrand?: boolean // aem props
  isStaticContent?: boolean // aem props
  additionalColumns?: AemAdditionalColumns[] // aem props
  substanceName?: string
  displayViewLink?: boolean
  substanceId?: string
  substancePosition?: number
  substanceKeyAttributes?:
    | SubstanceProductFieldsFragment['keyAttributes']
    | null
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    tContainer: {
      overflowX: 'visible',
    },
    tHeadRow: {
      whiteSpace: 'nowrap',
      '& .MuiTableCell-head': {
        padding: '0 .6rem',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: `${theme.typography.fontWeightMedium} !important`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    tHeadProductNumber: {
      width: 124,
    },
    tHeadSds: {
      width: 52,
      '&:empty': {
        width: 0,
      },
    },
    hideBelowMd: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    utilityRow: {
      display: 'flex',
      marginTop: theme.spacing(4),
      '&:empty': {
        display: 'none',
      },
    },
    showMoreLessButton: {
      flexShrink: 0,
    },
    compressWidth: {
      width: 0,
    },
  }
})

export const cardCategoryKeys = {
  ANTIBODY: 'ANTIBODY',
  SYRINGE_FILTERS: 'SYRINGE_FILTERS',
}

export const cardCategories = [
  cardCategoryKeys.ANTIBODY,
  cardCategoryKeys.SYRINGE_FILTERS,
]

// These headers will be removed once the srpKeyAttributes feature flag is removed
const AntibodyHeaders = ({
  formatMessage,
  displayBadgesOnProduct,
  showSdsColumn,
}) => {
  const classes = useStyles()
  return (
    <>
      <TableCell>{formatMessage(messages.CLONALITY)}</TableCell>
      <TableCell>{formatMessage(messages.APPLICATION)}</TableCell>
      <TableCell>
        {formatMessage({
          id: 'SPECIES_REACTIVITY',
          defaultMessage: 'Species Reactivity',
        })}
      </TableCell>
      <TableCell>{formatMessage(messages.CITATIONS)}</TableCell>
      {displayBadgesOnProduct && <TableCell />}
      <TableCell className={classes.tHeadSds}>
        {showSdsColumn && formatMessage(messages.SDS)}
      </TableCell>
      <TableCell colSpan={displayBadgesOnProduct ? 1 : 4} />
    </>
  )
}

// These headers will be removed once the srpKeyAttributes feature flag is removed
const SyringeFilterHeaders = ({
  formatMessage,
  displayBadgesOnProduct,
  showSdsColumn,
}) => {
  const classes = useStyles()
  return (
    <>
      <TableCell>{formatMessage(messages.PORE_SIZE)}</TableCell>
      <TableCell>{formatMessage(messages.DIAMETER)}</TableCell>
      <TableCell>{formatMessage(messages.STERILITY)}</TableCell>
      <TableCell>{formatMessage(messages.MATERIAL)}</TableCell>
      {displayBadgesOnProduct && <TableCell />}
      <TableCell className={classes.tHeadSds}>
        {showSdsColumn && formatMessage(messages.SDS)}
      </TableCell>
      <TableCell />
    </>
  )
}

/**
 * Function for generating the dynamic ID's for the
 * formatting of messages for translations
 * @param string the key attribute to generate the id for
 * @returns the created id for formatting the message
 * Pore Size -> KEY_ATTRIBUTE_PORE_SIZE e.g
 */
const generateKeyAttributeId = (string: string): string =>
  string
    .toUpperCase() // Converting the string to uppercase for Format ID
    .replace(/[^A-Z0-9]+/g, '_') // Replace non-alphanumeric characters with underscores
    .replace(/_+$/g, '') // Remove trailing underscores if any

const KeyAttributesHeaders = ({
  keyAttributes,
}: KeyAttributesProps): JSX.Element | null => {
  if (!keyAttributes?.length) return null

  return (
    <>
      {keyAttributes.map((keyAttribute) => {
        const { key, label } = keyAttribute
        // Camel Casing the label from the API response
        const cellValue = label.replace(/\b\w/g, (char: string): string =>
          char.toUpperCase()
        )
        const formattedMessageId = generateKeyAttributeId(cellValue)

        return (
          <TableCell key={`${key}_${label}`}>
            {formattedMessageId ? (
              <FormattedMessage
                id={formattedMessageId}
                defaultMessage={cellValue}
              />
            ) : (
              cellValue
            )}
          </TableCell>
        )
      })}
    </>
  )
}

const ProductHeaders = ({
  formatMessage,
  additionalColumns,
  isShowBrand,
  displayBadgesOnProduct,
  showSdsColumn,
  showDescriptionColumn,
}): JSX.Element => {
  const classes = useStyles()
  return (
    <>
      {showDescriptionColumn && (
        <TableCell>{formatMessage(messages.DESCRIPTION)}</TableCell>
      )}
      {isShowBrand && <TableCell>{formatMessage(messages.BRAND)}</TableCell>}
      {additionalColumns &&
        additionalColumns.map(({ text }) => (
          <TableCell key={text} data-additional="true">
            {text}
          </TableCell>
        ))}
      {displayBadgesOnProduct && <TableCell />}
      <TableCell className={classes.tHeadSds}>
        {showSdsColumn && formatMessage(messages.SDS)}
      </TableCell>
      <TableCell className={classes.compressWidth}>
        {formatMessage(messages.PRICING)}
      </TableCell>
    </>
  )
}

const ProductTable: React.FC<ProductTableProps> = ({
  products,
  displayBadgesOnProduct,
  focus,
  maxRows,
  isShowImage = false,
  isShowBrand = false,
  isStaticContent = false,
  additionalColumns,
  substanceName,
  displayViewLink,
  substanceId,
  substancePosition,
  substanceKeyAttributes,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const limit = maxRows || 5
  const maxProductsToShow = expanded ? products.length : limit
  const { formatMessage } = useIntl()
  const productIsAntibody =
    !!products.length && products[0]?.cardCategory === cardCategoryKeys.ANTIBODY
  const productIsSyringeFilter =
    !!products.length &&
    products[0]?.cardCategory === cardCategoryKeys.SYRINGE_FILTERS
  // STRAT-19275 * hide SDS column if none of the products in the table has an SDS to show
  const showSdsColumn =
    products.filter((p) => p?.sdsLanguages?.length > 0).length > 0

  if (expanded) {
    sendSeeAllEvent(substanceName, products.length)
  }

  // This will also be removed once the feature flag is removed for srpKeyAttributes
  const AltTableHeaders = productIsAntibody
    ? AntibodyHeaders
    : productIsSyringeFilter
      ? SyringeFilterHeaders
      : null

  const showDescriptionColumn =
    !featureFlags.srpKeyAttributes || !substanceKeyAttributes?.length

  return (
    <TableContainer className={classes.tContainer}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.tHeadRow}>
            {isShowImage ? (
              <TableCell />
            ) : !isStaticContent ? (
              <TableCell
                className={clsx(classes.hideBelowMd, classes.compressWidth)}
              >
                {formatMessage(messages.COMPARE)}
              </TableCell>
            ) : null}
            <TableCell classes={{ root: classes.tHeadProductNumber }}>
              {formatMessage(messages.PRODUCT_NO)}
            </TableCell>

            {!featureFlags.srpKeyAttributes && AltTableHeaders && (
              <AltTableHeaders
                formatMessage={formatMessage}
                displayBadgesOnProduct={displayBadgesOnProduct}
                showSdsColumn={showSdsColumn}
              />
            )}

            {featureFlags.srpKeyAttributes && (
              <KeyAttributesHeaders keyAttributes={substanceKeyAttributes} />
            )}

            {featureFlags.srpKeyAttributes && products[0]?.cardCategory && (
              <>
                {displayBadgesOnProduct && <TableCell />}
                <TableCell className={classes.tHeadSds}>
                  {showSdsColumn && formatMessage(messages.SDS)}
                </TableCell>
                <TableCell colSpan={displayBadgesOnProduct ? 1 : 4} />
              </>
            )}
            {!products[0]?.cardCategory && (
              <ProductHeaders
                formatMessage={formatMessage}
                additionalColumns={additionalColumns}
                isShowBrand={isShowBrand}
                displayBadgesOnProduct={displayBadgesOnProduct}
                showSdsColumn={showSdsColumn}
                showDescriptionColumn={showDescriptionColumn}
              />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {products
            .slice(0, maxProductsToShow)
            .map((product, index) =>
              product instanceof Error || !product ? null : (
                <Product
                  key={`${product.productKey}${product.brand.erpKey}-${index}`}
                  product={product}
                  displayBadgesOnProduct={displayBadgesOnProduct}
                  focus={focus}
                  isShowBrand={isShowBrand}
                  isShowImage={isShowImage}
                  isStaticContent={isStaticContent}
                  additionalColumns={additionalColumns}
                  productIsAntibody={productIsAntibody}
                  productIsSyringeFilter={productIsSyringeFilter}
                  displayViewLink={displayViewLink}
                  substancePosition={substancePosition}
                  substanceId={substanceId}
                  isShowDescriptionColumn={
                    !featureFlags.srpKeyAttributes ||
                    !product.keyAttributes?.length
                  }
                />
              )
            )}
        </TableBody>
      </Table>
      <div className={classes.utilityRow}>
        {products.length > limit && (
          <ShowMoreLessButton
            expanded={expanded}
            setExpanded={setExpanded}
            className={classes.showMoreLessButton}
            data-testid={`show-${!expanded ? 'more' : 'less'}`}
          />
        )}
      </div>
    </TableContainer>
  )
}

export default ProductTable
